import React, { useState } from "react";
import { Menu, Dropdown, Avatar, Modal } from "antd";
import { connect } from 'react-redux'
import { 
  EditOutlined, 
  SettingOutlined, 
  ShopOutlined, 
  QuestionCircleOutlined, 
  LogoutOutlined, 
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';


export const NavProfile = ({signOut, currentUser, language}) => {

  const menuItem = [
    {
      title: "Edit Profile",
      icon: EditOutlined ,
      path: "/"
      },
      
      {
      title: "Account Setting",
      icon: SettingOutlined,
      path: "/"
      },
      {
      title: "Billing",
      icon: ShopOutlined ,
      path: "/"
    },
    {
      title: "Help Center",
      icon: QuestionCircleOutlined,
      path: "/"
    }
  ]

  const profileImg = `https://ui-avatars.com/api/?name=${currentUser.name}`;
  const [visible, setVisible] = useState(false);
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{currentUser.name}</h4>
            <span className="text-muted">{currentUser.roles[0]}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={e => showModal()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">{language.signout}</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  function showModal(){
      setVisible(true);
  }

  function hideModal(){
    setVisible(false);
  }
  function confirmLogout(){
       setVisible(false);
      signOut();
  }

  return (
    <>
    <Modal title="Are you sure ?" visible={visible} onCancel={hideModal} onOk={confirmLogout}>
          <h5>Log out</h5>
    </Modal>  
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
    </>
  );
}

export default connect(null,{signOut})(NavProfile)
