import fetch from "auth/FetchInterceptor";

const DailyDepartmentReleasedTests = {};

DailyDepartmentReleasedTests.get = function (payload) {
  return fetch({
    url: `/reports/daily-department-released-tests?startdate=${payload.startDate}&enddate=${payload.endDate}`
  });
};

export default DailyDepartmentReleasedTests;