import{
    FETCH_USER_PREFERNCE_REQUEST,
    FETCH_USER_PREFERNCE_SUCCESS,
    FETCH_USER_PREFERNCE_FAILURE,
    UPDATE_USER_PREFERENCE_REQUEST,
    UPDATE_USER_PREFERENCE_SUCCESS,
    UPDATE_USER_PREFERENCE_FAILURE
} from "constants/UserPreferences"

export const onFetchUserPreferenceRequest = (userId) =>{
    return {
       type : FETCH_USER_PREFERNCE_REQUEST,
       payload : {
        userId
      }
    }
}

export const onFetchUserPreferenceSuccess = (payload) =>{
   return {
      type : FETCH_USER_PREFERNCE_SUCCESS,
      payload : payload
   }
}


export const onFetchUserPreferenceFailure = (errorMessage) =>{
   return {
      type : FETCH_USER_PREFERNCE_FAILURE,
      paylaod : errorMessage
   }
}

export const onUpdateUserPreferenceRequest = (preferences) =>{
    return {
       type : UPDATE_USER_PREFERENCE_REQUEST,
       payload : preferences
    }
}

export const onUpdateUserPreferenceSuccess = (payload) =>{
   return {
      type : UPDATE_USER_PREFERENCE_SUCCESS,
      payload : payload
   }
}


export const onUpdateUserPreferenceFailue = (errorMessage) =>{
   return {
      type : UPDATE_USER_PREFERENCE_FAILURE,
      paylaod : errorMessage
   }
}