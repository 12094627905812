import fetch from "auth/FetchInterceptor";

const DeclinedOrders = {};

DeclinedOrders.get = function (payload) {
  return fetch({
    url: `/reports/orders-declined?startdate=${payload.startDate}&enddate=${payload.endDate}&agency=${payload.agency}&department=${payload.department}`,
    method: "get",
  });
};

DeclinedOrders.getObx = function(payload = null){
  return fetch({
    url: "/reports/getOrderObx?visitUuid=" + payload,
    method: "get",
  })
}


DeclinedOrders.update = function (data) {
  return fetch({
    url: "/update-order-status",
    method: "post",
    data,
  });
};


export default DeclinedOrders;
