import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
    FETCH_PENDING_ORDER_REQUEST,
    FETCH_PENDING_ORDER_OBX_REQUEST,
    UPDATE_PENDING_ORDER_REQUEST,
} from "constants/OrderStatus/PendingOrders";

import {
  fetchPendingOrderSuccess,
  fetchPendingOrderFailure,
  fetchPendingOrderObxFailure,
  fetchPendingOrderObxSuccess,
  updatePendingOrderSuccess,
  updatePendingOrderFailure,

} from "redux/actions/OrderStatus/PendingOrders";

import PendingOrders from "services/OrderStatus/PendingOrders";
export function* onFetchPendingOrders() {
  yield takeLatest(FETCH_PENDING_ORDER_REQUEST, function* (payload) {
    try {
      const result = yield call(PendingOrders.get, payload.payload);
      if (result.status) {  
        yield put(fetchPendingOrderSuccess(result.data));
      } else {
        yield put(fetchPendingOrderFailure(result.message));
      }
    } catch (error) {
      yield put(fetchPendingOrderFailure(error.message));
    }
  });
}

export function* onFetchPendingOrderObx() {
  yield takeLatest(FETCH_PENDING_ORDER_OBX_REQUEST, function* (payload) {
    try {
      const result = yield call(PendingOrders.getObx, payload.payload.visitUuid);
      if (result.status) {
        yield put(fetchPendingOrderObxSuccess(result.data));
      } else {
        yield put(fetchPendingOrderObxFailure(result.message));
      }
    } catch (error) {
      yield put(fetchPendingOrderObxFailure(error.message));
    }
  });
}


export function* onUpdatePendingOrders() {
  yield takeLatest(UPDATE_PENDING_ORDER_REQUEST, function* ( payload ) {
    try {
      const result = yield call(
        PendingOrders.update,
        payload.payload
      );
      if (result.status) {
        message.success("Order Status Changed");
        // const data ={
        //   id: payload.payload[0].uuid,
        //   WebOrderApprovedStatus: payload.payload[0].webOrderApprovedStatus,
        // }
        // updateOrderStatusToOrbit(data);
        yield put(updatePendingOrderSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put( updatePendingOrderFailure(result.message ));
      } else {
        yield put( updatePendingOrderFailure,(result.message ));
      }
    } catch (error) {
      yield put(  updatePendingOrderFailure, ( error.message ));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchPendingOrders),
    fork(onFetchPendingOrderObx),
    fork(onUpdatePendingOrders),
  ]);
}
