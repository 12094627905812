import fetch from "auth/FetchInterceptor";

const UserService = {};

UserService.index = function () {
  return fetch({
    url: "/users",
    method: "get",
  });
};

UserService.create = function (data) {
  return fetch({
    url: "/users",
    method: "post",
    data,
  });
};

UserService.update = function (id, data) {
  return fetch({
    url: `/users/${id}`,
    method: "put",
    data,
  });
};

UserService.delete = function (id) {
  return fetch({
    url: `/rmusers`,
    method: "post",
    data: id
  });
};

export default UserService;
