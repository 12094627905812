import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Dropdown, Layout, Menu, Switch } from "antd";
import Flex from "components/shared-components/Flex";
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import { Amharic } from "lang/locales_latest/am_ET";
import { Arabic } from "lang/locales_latest/ar_SA";
import { English } from "lang/locales_latest/en_US";
import { French } from "lang/locales_latest/fr_FR";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { signOut } from "redux/actions/Auth";
import { onChangeLanguage, onMobileNavToggle, onSwitchTheme, toggleCollapsedNav } from 'redux/actions/Theme';
import utils from 'utils';
import Logo from './Logo';
import NavNotification from "./NavNotification";
import { NavProfile } from "./NavProfile";
import NavSearch from './NavSearch';
const { Header } = Layout;

export const HeaderNav = props => {
  const { currentUser, onSwitchTheme, signOut, navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentTheme, onChangeLanguage, language, currentSelectedLanguage } = props;
  const [searchActive, setSearchActive] = useState(false)
  const onSearchClose = () => {
    setSearchActive(false)
  }
  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')
    }
    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px'
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }

  useEffect(() => {
    if (!isMobile) {
      onSearchClose()
    }
  })


  function handleTheme(value) {
    if (value === true) {
      let currentTheme = "dark";
      onSwitchTheme(currentTheme);
      localStorage.setItem("darkMode", "dark");
    } else {
      let currentTheme = "light";
      localStorage.setItem("darkMode", "light");
      onSwitchTheme(currentTheme);
    }
  }


  function handleLanguageChange(value) {
    if (value === 'am') {
      onChangeLanguage(Amharic, 'am');
      localStorage.setItem("language", JSON.stringify(Amharic));
      localStorage.setItem("dropdownDefaultLanguageValue", 'am');
    } else if (value === 'en') {
      onChangeLanguage(English, 'en');
      localStorage.setItem("language", JSON.stringify(English));
      localStorage.setItem("dropdownDefaultLanguageValue", 'en');
    }
    else if (value === 'fr') {
      onChangeLanguage(French, 'fr');
      localStorage.setItem("language", JSON.stringify(French));
      localStorage.setItem("dropdownDefaultLanguageValue", 'fr');
    }
    else if (value === 'ar') {
      onChangeLanguage(Arabic, 'ar');
      localStorage.setItem("language", JSON.stringify(Arabic));
      localStorage.setItem("dropdownDefaultLanguageValue", 'ar');
    }
    window.location.reload();
  }


  const dropdownMenu = () => (
    <Menu>

      <Menu.Item onClick={() => handleLanguageChange("en")}>
        <Flex alignItems="center">
          {/* <FlagOutlined /> */}
          <span className="ml-2">🇺🇸 English</span>
        </Flex>
      </Menu.Item>

      <Menu.Item onClick={() => handleLanguageChange("am")}>
        <Flex alignItems="center">
          {/* <EditOutlined /> */}
          <span className="ml-2">🇪🇹 Amharic</span>
        </Flex>
      </Menu.Item>

      <Menu.Item onClick={() => handleLanguageChange("fr")}>
        <Flex alignItems="center">
          {/* <EditOutlined /> */}
          <span className="ml-2">🇫🇷 French</span>
        </Flex>
      </Menu.Item>

      <Menu.Item onClick={() => handleLanguageChange("ar")}>
        <Flex alignItems="center">
          {/* <EditOutlined /> */}
          <span className="ml-2">🇸🇦 Arabic</span>
        </Flex>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {
                isNavTop && !isMobile ?
                  null
                  :
                  <li className="ant-menu-item ant-menu-item-only-child" onClick={() => { onToggle() }}>
                    {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                  </li>
              }
            </ul>
          </div>

          <div className="nav-right">
            {/* <Select
                style={{ marginTop : "20px", marginRight : "30px"}}
                defaultValue={language[localStorage.getItem('dropdownDefaultLanguageValue')] ?? language["en"]}
                onChange={handleLanguageChange}
              >
                {["en", "am"].map((language) => (
                  <Option value={language} key={language}>
                    {language}
                  </Option>
                ))}
              </Select> */}
            <div style={{ marginTop: "2px", marginRight: "30px" }}>
              <Dropdown trigger={['click']} arrow placement="bottomRight" overlay={dropdownMenu()}>

                <span style={{ fontSize: '18px', cursor: 'pointer' }}>{currentSelectedLanguage === 'en' ? "US" : (currentSelectedLanguage === 'am' ? "ET" : (currentSelectedLanguage === 'ar' ? "AR" : "FR"))}</span>
                {/* <GlobalOutlined style={{ fontSize: '18px', cursor : 'pointer'}}/> */}
              </Dropdown>
            </div>


            <Switch checked={currentTheme === "dark" ? 1 : 0} checkedChildren={language.dark} unCheckedChildren={language.light} onChange={(event) => handleTheme(event)} title="Dark Mode" style={{ marginTop: "25px", marginRight: "30px" }}></Switch>
            <NavNotification />
            <NavProfile language={language} signOut={signOut} currentUser={currentUser} />

            {/* <NavPanel direction={direction} /> */}
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { currentUser } = auth
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction, language, currentSelectedLanguage } = theme;
  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction, currentUser, language, currentSelectedLanguage }
};

export default connect(mapStateToProps, { toggleCollapsedNav, onMobileNavToggle, signOut, onSwitchTheme, onChangeLanguage })(HeaderNav);