import fetch from "auth/FetchInterceptor";

const SpecimenTracking = {};

SpecimenTracking.get = function (payload) {
  return fetch({
    url:
      "/reports/specimen-tracking?" +
      "startdate=" +
      payload.startDate +
      "&enddate=" +
      payload.endDate +
      "&agency=" +
      payload.agency +
      "&department=" + payload.department
  });
};


SpecimenTracking.getListOfTests = function (payload) {
  return fetch({
    url:
      "/reports/specimen-tracking-list-of-tests?" +
      "sampleId=" +
      payload.sampleid 
  });
};


SpecimenTracking.updateSpecimenDrawByAndDrawLocation = function (payload) {
  return fetch({
    url:
      "/reports/specimen-tracking-update-phelebotmy",
      method : "post",
      data : payload
  });
};


// SpecimenTracking.updateSpecimenSrdTracking = function (payload) {
// console.log('----->service')
// const formData = new FormData();
//   formData.append("id", payload.record.id)
//   console.log(formData)
//   console.log(payload.record.id)
//   console.log('-------->end of fomrData')
//   return fetch({
//     url:
//       "/reports/specimen-tracking-update-srd",
//       method : "post",
//       data : payload,
//   });
// };

SpecimenTracking.updateSpecimenSrdTracking = function (payload) {
  // console.log('from the service file--rack name', payload.record.retainRackName);

  const formData = new FormData();
  formData.append('webSampleId', payload.record.webSampleId);
  formData.append('id', payload.record.id);
  formData.append('comment', payload.record.comment);
  formData.append('type', payload.record.type);
  // formData.append('option', payload.record.option);
  // formData.append('file', payload.record.file); // Append the file to FormData
  if(payload.record.srd_done){
    formData.append('srd_done', payload.record.srd_done);
  }
  //rejected
  if (payload.record.file) {
    formData.append('file', payload.record.file);
  }
  if (payload.record.option) {
    formData.append('option', payload.record.option);
  }
  
//receive or rejected---check if the last item in the order is received or rejected so as to remove it from the list
  if(payload.record.retain_done){
    formData.append('retain_done', payload.record.retain_done);
  }
//retain
  if (payload.record.retainRackName) {
    formData.append('retainRackName', payload.record.retainRackName);
  }

  if (payload.record.retaincolumn) {
    formData.append('retaincolumn', payload.record.retaincolumn);
  }

  if (payload.record.retainrow) {
    formData.append('retainrow', payload.record.retainrow);
  }
  //Dispose
  if (payload.record.disposalLocation) {
    formData.append('disposalLocation', payload.record.disposalLocation);
  }
  if (payload.record.disposalType) {
    formData.append('disposalType', payload.record.disposalType);
  }
  if (payload.record.disposeRackName) {
    formData.append('disposeRackName', payload.record.disposeRackName);
  }
  if (payload.record.disposeRow) {
    formData.append('disposeRow', payload.record.disposeRow);
  }
  if (payload.record.disposecolumn) {
    formData.append('disposecolumn', payload.record.disposecolumn);
  }
  if (payload.record.dispose_done) {
    formData.append('dispose_done', payload.record.dispose_done);
  }

  return fetch({
    url: "/reports/specimen-tracking-update-srd",
    method: "post",
    data: formData,
  });
};


export default SpecimenTracking;