import fetch from "auth/FetchInterceptor";
const AgencyTests = {};

AgencyTests.get = function (agencyId) {
    return fetch({
      url: `/client-company-test-association?agencyId=${agencyId}`,
      method: "get",
    });
  };

AgencyTests.create = function (payload){
    return fetch({
       url: "/client-company-test-association",
       method: "post",
       data : payload,
    });
};

AgencyTests.update = function ( payload){
     return fetch({
        url: "/client-company-test-association",
        method: "put",
        data : payload,
     });
};

export default AgencyTests;