import {
    FETCH_ALL_NOTIFICATION_FAILURE,
    FETCH_ALL_NOTIFICATIONS_REQUEST,
    FETCH_ALL_NOTIFICATIONS_SUCCESS,
    UPDATE_NOTIFICAITON_FAILURE,
    UPDATE_NOTIFICAITON_SUCCESS,
    UPDATE_NOTIFICAITON_REQUEST,
    SHOULD_SHOW_AND_ALERT_NOTIFICATION
} from "constants/Notifications"

export const fetchAllNotificationsRequest = () => ({
    type: FETCH_ALL_NOTIFICATIONS_REQUEST,
  });
  
  export const fetchAllNotificationsSuccess = (notifications) => ({
    type: FETCH_ALL_NOTIFICATIONS_SUCCESS,
    payload: notifications,
  });
  
  export const fetchAllNotificationsFailure = (error) => ({
    type: FETCH_ALL_NOTIFICATION_FAILURE,
    payload: error,
  });
  
  export const updateNotificationRequest = (validationErrors) => ({
    type: UPDATE_NOTIFICAITON_REQUEST,
    payload: validationErrors,
  });
  
  export const updateNotificationSuccess = (payload) => ({
    type: UPDATE_NOTIFICAITON_SUCCESS,
    payload : payload
  });
  
  export const updateNotificationFailure = (id) => ({
    type: UPDATE_NOTIFICAITON_FAILURE,
    payload: id,
  });

  export const alertAndshowNotificationRequest = (value) => ({
    type: SHOULD_SHOW_AND_ALERT_NOTIFICATION,
    payload: value,
  });
  