export const FETCH_SPECIMEN_TRACKING_REQUEST = "FETCH_SPECIMEN_TRACKING_REQUEST";
export const FETCH_SPECIMEN_TRACKING_SUCCESS = "FETCH_SPECIMEN_TRACKING_SUCCESS";
export const FETCH_SPECIMEN_TRACKING_FAILURE = "FETCH_SPECIMEN_TRACKING_FAILURE";


export const FETCH_LIST_OF_SPECIMEN_TESTS_REQUESET = "FETCH_LIST_OF_SPECIMEN_TESTS_REQUESET";
export const FETCH_LIST_OF_SPECIMEN_TESTS_SUCCESS = "FETCH_LIST_OF_SPECIMEN_TESTS_SUCCESS";
export const FETCH_LIST_OF_SPECIMEN_TESTS_FAILURE = "FETCH_LIST_OF_SPECIMEN_TESTS_FAILURE";

export const UPDATE_SPECIMEN_PHLEBOTOMY_REQUESET = "UPDATE_LIST_OF_SPECIMEN_TESTS_REQUESET";
export const UPDATE_SPECIMEN_PHLEBOTOMY_SUCCESS = "UPDATE_LIST_OF_SPECIMEN_TESTS_SUCCESS";
export const UPDATE_SPECIMEN_PHLEBOTOMY_FAILURE = "UPDATE_LIST_OF_SPECIMEN_TESTS_FAILURE";

export const UPDATE_SPECIMEN_SRD_REQEUST = "UPDATE_SPECIMEN_SRD_REQEUST";
export const UPDATE_SPECIMENT_SRD_SUCCESS = "UPDATE_SPECIMENT_SRD_SUCCESS";
export const UPDATE_SPECIMEN_SRD_FAILURE = "UPDATE_SPECIMEN_SRD_FAILURE";