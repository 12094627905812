import {
  FETCH_TOPAGENCIES_SUCCESS,
  FETCH_TOPAGENCIES_REQUEST,
  FETCH_TOPAGENCIES_FAILURE,
} from "constants/TopAgencies";

const initState = {
  loading: false,
  list: [],
  errorMessage: "",
};

const topagencies = (state = initState, action) => {
  switch (action.type) {
    case FETCH_TOPAGENCIES_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };
    case FETCH_TOPAGENCIES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_TOPAGENCIES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default topagencies;
