import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { FETCH_USER_PREFERNCE_REQUEST, UPDATE_USER_PREFERENCE_REQUEST} from "constants/UserPreferences"; 
import { onUpdateUserPreferenceFailue, onUpdateUserPreferenceSuccess, onFetchUserPreferenceFailure, onFetchUserPreferenceSuccess } from "redux/actions";
import { USER_PREFERENCE } from "redux/constants/Auth";
import UserPreferenceService from "services/UserPreference";

export function* onFetchUserPreference() {
    yield takeLatest(FETCH_USER_PREFERNCE_REQUEST, function* (payload) {
      try {
       const result = yield call(UserPreferenceService.get, payload.payload);
        if (result.status) {
          yield put(onFetchUserPreferenceSuccess(result.data));
        } else {
          yield put(onFetchUserPreferenceFailure(result.message));
        }
      } catch (error) {
        message.error("unexpected error occured");
        yield put(onFetchUserPreferenceFailure(error.message));
      }
    });
  }

  export function* onUpdateUserPreference() {
    yield takeLatest(UPDATE_USER_PREFERENCE_REQUEST, function* (payload) {
      try {
       const result = yield call(UserPreferenceService.update, payload.payload);
        if (result.status) {
          // message.success("Preference synced");
          localStorage.setItem(USER_PREFERENCE, JSON.stringify(result.data ?? []));
          yield put(onUpdateUserPreferenceSuccess(result.data));
        } else {
          yield put(onUpdateUserPreferenceFailue(result.message));
        }
      } catch (error) {
        message.error("unexpected error occured");
        yield put(onUpdateUserPreferenceFailue(error.message));
      }
    });
  }

export default function* rootSaga() {
    yield all([
        fork(onFetchUserPreference),
        fork(onUpdateUserPreference)
    ]);
  }
  