import{
    FETCH_USER_PREFERNCE_REQUEST,
    FETCH_USER_PREFERNCE_SUCCESS,
    FETCH_USER_PREFERNCE_FAILURE,
    UPDATE_USER_PREFERENCE_REQUEST,
    UPDATE_USER_PREFERENCE_SUCCESS,
    UPDATE_USER_PREFERENCE_FAILURE
} from "constants/UserPreferences"

const initialValues = {
    list : [],
    errorMessages : "",
    loading : false,
}


const UserPreference = ( state = initialValues, action) =>{
    switch(action.type){
        case FETCH_USER_PREFERNCE_REQUEST : 
            return {
                loading : true
            }
        case FETCH_USER_PREFERNCE_SUCCESS : 
            return {
                loading : false,
                list : action.payload
            }
        case FETCH_USER_PREFERNCE_FAILURE : 
            return {
                loading : false,
                list : []
            }
        case UPDATE_USER_PREFERENCE_REQUEST :
            return {
                loading : true,
            }
        case UPDATE_USER_PREFERENCE_SUCCESS : 
            return {
                loading : false,
                list : action.payload
            }
        case UPDATE_USER_PREFERENCE_FAILURE : 
            return {
                loading : false,
                list : []
            }
        default : return state;
    }
}

export default UserPreference;