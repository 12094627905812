import {
  FETCH_RESOURCE_REQUEST,
  FETCH_RESOURCE_REQUEST_FAILURE,
  FETCH_RESOURCE_REQUSET_SUCCESS,
  CREATE_RESOURCE_REQUEST,
  CREATE_RESOURCE_REQUSET_SUCCESS ,
  CREATE_RESOURCE_REQUEST_FAILURE ,
  UPDATE_RESOURCE_REQUEST,
  UPDATE_RESOURCE_SUCCESS,
  UPDATE_RESOURCE_FAILURE,
  UPDATE_RESOURCE_REQUEST_STATUS,
  UPDATE_RESOURCE_REQUEST_STATUS_SUCCESS,
  UPDATE_RESOURCE_REQUEST_STATUS_FAILURE,
} from "constants/Logistics"
import { updateRequest,addLogistics,updateRequestStatus} from "utils/Logistics";

const initState = {
  list : [],
  loading : false,
  createLoading : false,
  errorMessage : "",
  
}

const Logistics = (state = initState, action) =>{
 switch(action.type)
 {
    case FETCH_RESOURCE_REQUEST:
     return {
         ...state,
         loading : true
     } 
    case FETCH_RESOURCE_REQUSET_SUCCESS : 
     return {
          ...state, 
          loading : false,
          list : action.payload
             }
     case FETCH_RESOURCE_REQUEST_FAILURE :
     return {
         ...state,
         loading : false,
         errorMessage : action.payload
     }
     case CREATE_RESOURCE_REQUEST :
      return {
        ...state,
        loading: true,
      };
     case CREATE_RESOURCE_REQUSET_SUCCESS:
      return {
        ...state,
        loading: false,
        list: addLogistics(state, action),
        
        
      };
    case CREATE_RESOURCE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case UPDATE_RESOURCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: updateRequest(state, action),
      };
    case UPDATE_RESOURCE_FAILURE:
      return {
        ...state,
        loading : false,
        errorMessage: action.payload.error,
      };
      case UPDATE_RESOURCE_REQUEST_STATUS:
        return {
          ...state,
          loading: true,
        };
      case UPDATE_RESOURCE_REQUEST_STATUS_SUCCESS:
        return {
          ...state,
          loading: false,
          list: updateRequestStatus(state, action),
        };
      case UPDATE_RESOURCE_REQUEST_STATUS_FAILURE:
        return {
          ...state,
          loading : false,
          errorMessage: action.payload.error,
        };
    default:
      return state;
  }
    
 }

 
export default Logistics;