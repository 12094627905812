import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import {     UPLOAD_FILE_REQUEST} from "constants/UploadFile"; 
import { onUploadMicroBiologyResultSuccess, onUploadMicroBiologyResultFailure } from "redux/actions";
import UploadFileService from "services/UploadFile";

  
export function* onUploadFile() {
    yield takeLatest(UPLOAD_FILE_REQUEST, function* (payload) {
      try {
       const result = yield call(UploadFileService.upload, payload.payload);
        if (result.status) {
          message.success("Uploaded Successfully");
          yield put(onUploadMicroBiologyResultSuccess(result.data));
        } else {
            message.error("unable to upload the file");
          yield put(onUploadMicroBiologyResultFailure(result.message));
        }
      } catch (error) {
        message.error("unexpected error occured");
        yield put(onUploadMicroBiologyResultFailure(error.message));
      }
    });
  }

export default function* rootSaga() {
    yield all([
      fork(onUploadFile),
    ]);
  }
  