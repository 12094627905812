import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";

import {
  FETCH_LIST_OF_SPECIMEN_TESTS_REQUESET,
  FETCH_SPECIMEN_TRACKING_REQUEST,
  UPDATE_SPECIMEN_PHLEBOTOMY_REQUESET,
  UPDATE_SPECIMEN_SRD_REQEUST
} from "constants/specimen_tracking";

import {
  fetchListOfSpecimenTestsFailure,
  fetchListOfSpecimenTestsSuccess,
  fetchSpecimenTrackingFailure,
  fetchSpecimenTrackingSuccess,
  updateSpecimenSrdFailure,
  updateSpecimenSrdSuccess,
  updateSpecimenTestPhelebotomySuccess,
  updateSpecimenTestsPhelebotomyFailure,
  
} from "redux/actions/SpecimenTracking";

import SpecimenTracking from "services/SpecimenTracking";

export function* onFetchSpecimenTracking() {
  yield takeLatest(FETCH_SPECIMEN_TRACKING_REQUEST, function* (payload) {
    try {
      const result = yield call(SpecimenTracking.get, payload.payload);
      if (result.status) {
        yield put(fetchSpecimenTrackingSuccess(result.data));
      } else {
        yield put(fetchSpecimenTrackingFailure(result.message));
      }
    } catch (error) {
      yield put(fetchSpecimenTrackingFailure(error.message));
    }
  });
}

export function* onFetchListOfTestsOfSpecimenTracking() {
  yield takeLatest(FETCH_LIST_OF_SPECIMEN_TESTS_REQUESET, function* (payload) {
    try {
      const result = yield call(SpecimenTracking.getListOfTests, payload.payload);
      if (result.status) {
        yield put(fetchListOfSpecimenTestsSuccess(result.data));
      } else {
        yield put(fetchListOfSpecimenTestsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchListOfSpecimenTestsFailure(error.message));
    }
  });
}

export function* onUpdateSpecimenDrawByAndDrawLocation() {
  yield takeLatest(UPDATE_SPECIMEN_PHLEBOTOMY_REQUESET, function* (payload) {
    try {
      const result = yield call(SpecimenTracking.updateSpecimenDrawByAndDrawLocation, payload.payload);
      if (result.status) {
        message.success("Specimen info updated successfully");
        yield put(updateSpecimenTestPhelebotomySuccess(payload.payload.record.specimen_id));
      } else {
        yield put(updateSpecimenTestsPhelebotomyFailure(result.message));
      }
    } catch (error) {
      yield put(updateSpecimenTestsPhelebotomyFailure(error.message));
    }
  });
}

export function* onUpdateSrdSpecimenTracking() {
  yield takeLatest(UPDATE_SPECIMEN_SRD_REQEUST, function* (payload) {
    try {
      const result = yield call(SpecimenTracking.updateSpecimenSrdTracking, payload.payload);
      if (result.status) {
        message.success("Specimen info updated successfully");
        yield put(updateSpecimenSrdSuccess(payload.payload.record.id));
      } else {
        yield put(updateSpecimenSrdFailure(result.message));
      }
    } catch (error) {
      yield put(updateSpecimenSrdFailure(error.message));
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(onFetchSpecimenTracking),
    fork(onFetchListOfTestsOfSpecimenTracking),
    fork(onUpdateSpecimenDrawByAndDrawLocation),
    fork(onUpdateSrdSpecimenTracking)
  ]);
}
