import React from 'react'
import { APP_NAME } from 'configs/EnvironmentConfig';
import { connect } from 'react-redux';

const  Footer = ({language}) => {
	return (
		<footer className="footer">
			<span>{language.copyright}  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span>{language.icl_all_right_reserved}</span>
		</footer>
	)
}


const mapStateToProps = (state) => ({
	language : state.theme.language
  });
  
  const mapDispatchToProps = (dispatch) => ({
  });

  export default connect(mapStateToProps,mapDispatchToProps)(Footer)