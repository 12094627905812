import fetch from "auth/FetchInterceptor";

const UserPreferenceService = {};


UserPreferenceService.get = function (userId) {
    return fetch({
      url: "/get-user-preference",
      method: "get",
      data : userId
    });
  };

UserPreferenceService.update = function (preference) {
  return fetch({
    url: "/update-user-preference",
    method: "post",
    data : preference
  });
};


export default UserPreferenceService;