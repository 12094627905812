import {
  DELETE_PATIENT_FAILURE,
  DELETE_PATIENT_REQUEST,
  DELETE_PATIENT_SUCCESS,
  FETCH_PATIENTS_FAILURE,
  FETCH_PATIENTS_REQUEST,
  FETCH_PATIENTS_SUCCESS,
  CREATE_PATIENT_FAILURE,
  CREATE_PATIENT_REQUEST,
  CREATE_PATIENT_SUCCESS,
  SHOW_PATIENT_EDIT_FORM,
  SHOW_PATIENT_NEW_FORM,
  UPDATE_PATIENT_FAILURE,
  UPDATE_PATIENT_REQUEST,
  UPDATE_PATIENT_SUCCESS,
  SET_PATIENT_VALIDATION_ERRORS,
} from "constants/Patient";

export const fetchPatientsRequest = () => ({
  type: FETCH_PATIENTS_REQUEST,
});

export const fetchPatientsSuccess = (patients) => ({
  type: FETCH_PATIENTS_SUCCESS,
  payload: patients,
});

export const fetchPatientsFailure = (error) => ({
  type: FETCH_PATIENTS_FAILURE,
  payload: error,
});

export const setPatientValidationErrors = (validationErrors) => ({
  type: SET_PATIENT_VALIDATION_ERRORS,
  payload: validationErrors,
});

export const togglePatientEditForm = () => ({
  type: SHOW_PATIENT_EDIT_FORM,
});

export const togglePatientNewForm = () => ({
  type: SHOW_PATIENT_NEW_FORM,
});

export const updatePatientRequest = (patient) => ({
  type: UPDATE_PATIENT_REQUEST,
  payload: patient,
});

export const updatePatientSuccess = (patient) => ({
  type: UPDATE_PATIENT_SUCCESS,
  payload: patient,
});

export const updatePatientFailure = (error) => ({
  type: UPDATE_PATIENT_FAILURE,
  payload: error,
});

export const deletePatientRequest = (row) => ({
  type: DELETE_PATIENT_REQUEST,
  payload: row,
});

export const deletePatientSuccess = (patient) => ({
  type: DELETE_PATIENT_SUCCESS,
  payload: patient,
});

export const deletePatientFailure = (error) => ({
  type: DELETE_PATIENT_FAILURE,
  payload: error,
});

export const createPatientRequest = (patient) => ({
  type: CREATE_PATIENT_REQUEST,
  payload: patient,
});

export const createPatientSuccess = (patient) => ({
  type: CREATE_PATIENT_SUCCESS,
  payload: patient,
});

export const createPatientFailure = (error) => ({
  type: CREATE_PATIENT_FAILURE,
  payload: error,
});
