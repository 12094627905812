import {
    FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST,
    FETCH_DAILY_FINANCIAL_SUMMARY_FAILURE,
    FETCH_DAILY_FINANCIAL_SUMMARY_SUCCESS
} from "constants/DailyFinancialSummary";

// export const fetchDailyFinancialSummaryRequest = (startDate, endDate, agency, cashiercode, cashorcredit) =>({
//     type : FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST,
//     payload : {
//         startDate, 
//         endDate, 
//         agency,
//         cashiercode,
//         cashorcredit,
//     }
// });
export const fetchDailyFinancialSummaryRequest = (startDate, endDate, agency, cashiercode, cashorcredit) => {
    const payload = {
      startDate, 
      endDate, 
      agency,
      cashiercode,
      cashorcredit,
    };
  
    console.log("Payload sent--->:", payload); // Adding console.log to see the value of the payload
  
    return {
      type: FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST,
      payload: payload
    };
  };

export const fetchDailyFinancialSummarySuccess = (payload) =>({
    type : FETCH_DAILY_FINANCIAL_SUMMARY_SUCCESS,
    payload : payload
});

export const fetchDailyFinancialSummaryFailure = (payload) => ({
    type : FETCH_DAILY_FINANCIAL_SUMMARY_FAILURE,
    payload : payload
});

