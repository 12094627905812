//icladdis firebase
const FirebaseConfig  = {
  apiKey: "AIzaSyCRtU7kzZ3VHC-aCSCDQGUNK7gB5ct80Kc",
  authDomain: "icladdisapps.firebaseapp.com",
  projectId: "icladdisapps",
  storageBucket: "icladdisapps.appspot.com",
  messagingSenderId: "522247445936",
  appId: "1:522247445936:web:6f8f74bc51ef39e4d6ca75",
  measurementId: "G-FS0HQPMH2F"
};

// fucking -firebase
// const FirebaseConfig = {
//   apiKey: "AIzaSyAtTwAgXsyUjcMrdMtq3yvC1-_mzgXhPPk",
//   authDomain: "fucking-firebase-c945f.firebaseapp.com",
//   projectId: "fucking-firebase-c945f",
//   storageBucket: "fucking-firebase-c945f.appspot.com",
//   messagingSenderId: "1009290693603",
//   appId: "1:1009290693603:web:c38538bfc6a2122960eed6",
//   measurementId: "G-2GF15B3ZDS"
// };

export default FirebaseConfig   

