import Loading from "components/shared-components/Loading";
import { DOCTOR_PREFIX_PATH } from "configs/AppConfig";
import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router";
import { CURRENT_USER } from "redux/constants/Auth";
import PageNotFoundPage from "views/PageNotFound";
import UnAuthorizedPage from "views/UnAuthorized";
import DoctorDashboard from "./dashboard/list/index";
import { VIEW_ALL_DISCREPANCIES, VIEW_ALL_INCOMPLETE_RESULTS, VIEW_ALL_RELEASED_RESULTS, VIEW_COMPLETED_RESULTS, VIEW_NEWORDERS, VIEW_PANIC_AND_ABNORMAL_TESTS, VIEW_SEASONAL_REPORT } from "configs/UserPermissions";
import { DoesThisUserHasAPermissionToThisComponent } from "utils/CurrentUserUtil";
 
const DoctorAppViews = () => {
  const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
  const role = currentUser?.role;
  return (
    <div>
      <Suspense fallback={<Loading cover="content" />}>
      { 
         role === "doctor" ? 
         <Switch>
            <Route 
             exact
             path={`${DOCTOR_PREFIX_PATH}`}
            >
              <DoctorDashboard/>
            </Route>
             
          <Route
            exact
            path={`${DOCTOR_PREFIX_PATH}/dashboard`}
            component={lazy(() => import(`./dashboard/list/index`))}
          />
            {DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_DISCREPANCIES) && (
                <Route
                  exact
                  path={`${DOCTOR_PREFIX_PATH}/results/corrected/all`}
                  component={lazy(() => import(`../admin-views/discrepancy/all/list`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_RELEASED_RESULTS) && (
                <Route
                  exact
                  path={`${DOCTOR_PREFIX_PATH}/results/released/all`}
                  component={lazy(() => import(`./results/all/list`))}
                />
            )}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_SEASONAL_REPORT) && (
                <Route
                  exact
                  path={`${DOCTOR_PREFIX_PATH}/seasonal-report`}
                  component={lazy(() => import(`./seasonal-report/index`))}
                />
            )}
          {DoesThisUserHasAPermissionToThisComponent(VIEW_NEWORDERS) && (
              <Route
                exact
                path={`${DOCTOR_PREFIX_PATH}/order/new`}
                component={lazy(() => import(`../admin-views/neworder/neworder/index`))}
              />
            )}
          
          {DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_INCOMPLETE_RESULTS) && (
            <Route
              exact
              path={`${DOCTOR_PREFIX_PATH}/results/incomplete/all`}
              component={lazy(() => import(`../admin-views/released-results/incomplete-results/list`))}
            />
          )}

          {DoesThisUserHasAPermissionToThisComponent(VIEW_COMPLETED_RESULTS) && (
            <Route
              exact
              path={`${DOCTOR_PREFIX_PATH}/results/completed/all`}
              component={lazy(() => import(`../admin-views/released-results/completed-results/list`))}
            />
          )}

          {DoesThisUserHasAPermissionToThisComponent(VIEW_PANIC_AND_ABNORMAL_TESTS) && (
            <Route
              exact
              path={`${DOCTOR_PREFIX_PATH}/results/panic-and-abnormal/all`}
              component={lazy(() => import(`../admin-views/released-results/panicandabnormaltests/list/index`))} 
            />
          )}

            <Route
            path="*">
                <PageNotFoundPage/>
            </Route>

        </Switch> : 
       <UnAuthorizedPage/>
      }
      </Suspense>
    </div>
  );
};

export default DoctorAppViews;
