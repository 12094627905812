import { MONTH_DAY_YEAR_WHILE_MONTH_IN_STRING_FORMAT } from "constants/DateTimeUtilConstants"
import { changeDateFormatAndReturnNewDate } from "./DateTimeUtil"


export const changeDateToHumanReadableFormat = (data) =>{
    return data.map((row) =>{
        row['insertedTimestamp'] = changeDateFormatAndReturnNewDate(row['insertedTimestamp'], MONTH_DAY_YEAR_WHILE_MONTH_IN_STRING_FORMAT);
        console.log(row['insertedTimestamp']);
        return row;
    })
}