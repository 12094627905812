import fetch from "auth/FetchInterceptor";

const PickupRequest = {};

PickupRequest.get = function (payload) {
  return fetch({
    url:
      "/logisPickupRequest?" +
      "startdate=" +
      payload.startDate +
      "&" +
      "enddate=" +
      payload.endDate +
      "&" +
      "agency=" + payload.agency + "&department=null",
    method: "get",
  });
};


PickupRequest.updateOrderStatus = function (payload) {
  return fetch({
       url: '/logisPickupRequest',
       method: 'POST',
       data : payload
   });
}


PickupRequest.updateThePickupStatusForAgency = function (payload) {
  return fetch({
       url: '/updateAgencyLogisPickupRequest',
       method: 'POST',
       data : payload
   });
}
export default PickupRequest;
