import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST,
  SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS,
  SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE,
  SIGN_UP_WITH_EMAIL_AND_PASSWORD_REQUEST,
  SIGN_UP_WITH_EMAIL_AND_PASSWORD_SUCCESS,
  SIGN_UP_WITH_EMAIL_AND_PASSWORD_FAILURE,
} from "../constants/Auth";



export const signIn = (user) => {
  return {
    type: SIGNIN,
    payload: user,
  };
};

export const authenticated = (data) => {
  return {
    type: AUTHENTICATED,
    payload: data,
  };
};

export const signOut = () => {
  return {
    type: SIGNOUT,
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  };
};

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user,
  };
};

export const signUpSuccess = (data) => {
  return {
    type: SIGNUP_SUCCESS,
    payload: data,
  };
};

export const signInWithGoogle = () => {
  return {
    type: SIGNIN_WITH_GOOGLE,
  };
};

export const signInWithGoogleAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    token,
  };
};

export const signInWithFacebook = () => {
  return {
    type: SIGNIN_WITH_FACEBOOK,
  };
};

export const signInWithFacebookAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    token,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const signInWithEmailAndPasswordRequest = (userCredentials) => ({
  type: SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST,
  payload: userCredentials,
});

export const signInWithEmailAndPasswordSuccess = () => ({
  type: SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS,
});

export const signInWithEmailAndPasswordFailure = () => ({
  type: SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE,
});

export const signUpWithEmailAndPasswordRequest = (userCredentials) => ({
  type: SIGN_UP_WITH_EMAIL_AND_PASSWORD_REQUEST,
  payload: userCredentials,
});

export const signUpWithEmailAndPasswordSuccess = () => ({
  type: SIGN_UP_WITH_EMAIL_AND_PASSWORD_SUCCESS,
});

export const signUpWithEmailAndPasswordFailure = (error) => ({
  type: SIGN_UP_WITH_EMAIL_AND_PASSWORD_FAILURE,
  payload: error
});



