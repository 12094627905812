import fetch from "auth/FetchInterceptor";

const Costservice = {};

Costservice.get = function (payload) {
  return fetch({
    url: `/getFinanicialReportPdfs?startdate=${payload.startDate}&enddate=${payload.endDate}&agency=${payload.agency}` ,
  method: "get",
  });
};

export default Costservice;