import fetch from "auth/FetchInterceptor";

const NewOrderService = {};

NewOrderService.index = function () {
  return fetch({
    url: "/getPanels",
    method: "get",
  });
};

NewOrderService.getLocations = function () {
  return fetch({
    url: "/locationlookup",
    method: "get",
  });
};

// NewOrderService.searchpatient = function (searchdata) {
//   return fetch({
//     url: `/searchpatient/${searchdata.phonenumber}/${searchdata.sex}/${searchdata.patientid}/${searchdata.sampleid}`,
//     method: "get",
//   });
//  };

NewOrderService.searchpatient = function (searchdata) {
  return fetch({
    url: "/searchpatient",
    method: "post",
    data: searchdata
  });
};

NewOrderService.getzone = function (parentid) {
  const url = "/locationlookupzone"
  return fetch({
    url: url,
    method: "post",
    data: parentid
  });
};

NewOrderService.create = function (data) {
  return fetch({
    url: "/neworder",
    method: "post",
    data,
  });
};

NewOrderService.updateCacheRegister = function(data){
  return fetch({
    url : "/update-cache-register-machine",
    method : "post",
    data,
  })
}

export default NewOrderService;
