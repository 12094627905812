import { FORGOT_PASSWORD_FILURE, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAILURE, UPDATE_PASSWORD_REQUEST, UPDATE_PASSWORD_SUCCESS } from "constants/ForgotPassword"


const initialState = {
    isLoading: false,
    errorMessage: "",
    data: {},
    isPasswordUpdatedSuccssfully : false
}

const ForgotPassword = (state = initialState, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_REQUEST:
            return {
                isLoading: true,
            }

        case FORGOT_PASSWORD_SUCCESS:
            return {
                isLoading: false,
                data: action.payload
            }

        case FORGOT_PASSWORD_FILURE:
            return {
                isLoading: false,
                errorMessage: action.payload
            }
        
        case UPDATE_PASSWORD_REQUEST:
            return {
                isLoading: true,
            }

        case UPDATE_PASSWORD_SUCCESS:
            return {
                isLoading: false,
                data: action.payload,
                isPasswordUpdatedSuccssfully : true,
            }

        case UPDATE_PASSWORD_FAILURE:
            return {
                isLoading: false,
                isPasswordUpdatedSuccssfully : false
            }
        default:
            return state;
    }
}

export default ForgotPassword;