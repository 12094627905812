import {
    FETCH_SPECIMEN_TRACKING_REQUEST,
    FETCH_SPECIMEN_TRACKING_SUCCESS,
    FETCH_SPECIMEN_TRACKING_FAILURE,
    FETCH_LIST_OF_SPECIMEN_TESTS_REQUESET,
    FETCH_LIST_OF_SPECIMEN_TESTS_SUCCESS,
    FETCH_LIST_OF_SPECIMEN_TESTS_FAILURE,
    UPDATE_SPECIMEN_PHLEBOTOMY_REQUESET,
    UPDATE_SPECIMEN_PHLEBOTOMY_SUCCESS,
    UPDATE_SPECIMEN_PHLEBOTOMY_FAILURE,
    UPDATE_SPECIMEN_SRD_REQEUST,
    UPDATE_SPECIMENT_SRD_SUCCESS,
    UPDATE_SPECIMEN_SRD_FAILURE
} from "constants/specimen_tracking";
import removeItem from "utils/SpecimenTracking";

const initState = {
     loading : false, 
     list : [],
     listOfTests : [],
     loadingSpecimenList : false,
     errorMessage : {}
}

const  SpecimenTracking = (state = initState, action) =>{
     switch(action.type){
        case FETCH_SPECIMEN_TRACKING_REQUEST :
            return {
                ...state,
                loading : true
            }

        case FETCH_SPECIMEN_TRACKING_SUCCESS :
            return {
                ...state,
                list : action.payload,
                loading : false
            }

        case FETCH_SPECIMEN_TRACKING_FAILURE :
            return {
                ...state,
                list : [],
                loading : false
            }     

            case FETCH_LIST_OF_SPECIMEN_TESTS_REQUESET :
                return {
                    ...state,
                    loadingSpecimenList : true
                }
    
            case FETCH_LIST_OF_SPECIMEN_TESTS_SUCCESS :
                return {
                    ...state,
                    listOfTests : action.payload,
                    loadingSpecimenList : false
                }
    
            case FETCH_LIST_OF_SPECIMEN_TESTS_FAILURE:
                return {
                    ...state,
                    loadingSpecimenList : false,
                    error: action.payload.error,
                }


            case UPDATE_SPECIMEN_PHLEBOTOMY_REQUESET:
                return {
                    ...state,
                    loadingSpecimenList:true
                }

            case UPDATE_SPECIMEN_PHLEBOTOMY_SUCCESS:
                return{
                    ...state,
                    loadingSpecimenList : false,
                    listOfTests: removeItem(state.listOfTests, action.payload)
                }

            case UPDATE_SPECIMEN_PHLEBOTOMY_FAILURE:
                return{
                    ...state,
                    loadingSpecimenList : false,
                }


                case UPDATE_SPECIMEN_SRD_REQEUST:
                    return {
                        ...state,
                        loadingSpecimenList:true
                    }
    
                case UPDATE_SPECIMENT_SRD_SUCCESS:
                    return{
                        ...state,
                        loadingSpecimenList : false,
                        listOfTests: removeItem(state.listOfTests, action.payload)
                    }
    
                case UPDATE_SPECIMEN_SRD_FAILURE:
                    return{
                        ...state,
                        loadingSpecimenList : false
                    }

        default : return state;       
     }
}

export default SpecimenTracking;