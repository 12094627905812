import {
    FETCH_INPERSON_DELIVERY_FAILURE,
    FETCH_INPERSON_DELIVERY_REQUEST,
    FETCH_INPERSON_DELIVERY_SUCCESS
} from "constants/InPersonDelivery"
const initialList = {
    loading : false,
    list : [],
    errorMessage : ""
}

 const InPersonDelivery = (state = initialList, action) =>{
    switch(action.type)
    {
         case FETCH_INPERSON_DELIVERY_REQUEST: 
            return {
                loading : true,
            }
         case FETCH_INPERSON_DELIVERY_SUCCESS:
            return{
            loading : false,
            list :  
                action.payload
              
            }

         case FETCH_INPERSON_DELIVERY_FAILURE:
            return{
                loading : false,
                errorMessage : action.payload
            }

        default :
        return state
    }
}

export default InPersonDelivery;