import { all, call, fork, put, takeLatest,delay,race } from "@redux-saga/core/effects";
import { PENDING_RESULT_REFRESH_TIME, PENDING_RESULT_STARTDATE } from "configs/AppConfig";
import { FETCH_CORELAB_RESULTS_REQUESET } from "constants/CoreLab";
import { fetchCoreLabResultsFailure, fetchCoreLabResultsRequests, fetchCoreLabResultsSuccess } from "redux/actions";
import CoreLabResultsServices from "services/CoreLab";
import moment from "moment"
export function* onFetchCoreLabResults() {
  yield takeLatest(FETCH_CORELAB_RESULTS_REQUESET, function* (payload) {
    while(true){
    try {
       const result = yield call(CoreLabResultsServices.get, payload.payload);
        if (result.status) {
            yield put(fetchCoreLabResultsSuccess(result.data));
            yield delay(PENDING_RESULT_REFRESH_TIME);
            yield put(fetchCoreLabResultsRequests(moment().subtract(PENDING_RESULT_STARTDATE, 'days').format("YYYYMMDD").toString() + "000000"));
          } else {
            yield put(fetchCoreLabResultsFailure(result.message));
            yield delay(PENDING_RESULT_REFRESH_TIME);
            yield put(fetchCoreLabResultsRequests(moment().subtract(PENDING_RESULT_STARTDATE, 'days').format("YYYYMMDD").toString() + "000000"));
          }
        } catch (error) {
          yield put(fetchCoreLabResultsFailure(error.message));
          yield delay(PENDING_RESULT_REFRESH_TIME);
          yield put(fetchCoreLabResultsRequests(moment().subtract(PENDING_RESULT_STARTDATE, 'days').format("YYYYMMDD").toString() + "000000"));
        }
      }
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchCoreLabResults)]);
}
