import fetch from 'auth/FetchInterceptor'

const forgotPasswordService = {}

forgotPasswordService.sendRequest = function (email) {
    return fetch({
        url: '/sendPasswordResetLink',
        method: 'post',
        data: { email }
    })
}

forgotPasswordService.updatePassword = function (data) {
    return fetch({
        url: `/updatePasswordThroughToken`,
        method: 'post',
        data
    })
}

export default forgotPasswordService