export const French = {
    "type": "FRA",
    "home": "Accueil",
    "upload": "Télécharger un fichier",
    "start_upload": "Démarrer le téléchargement",
    "uploading": "En cours de téléchargement",
    "user_management": "Gestion des utilisateurs",
    "change_password": "Changer le mot de passe",
    "role": "Rôles",
    "user": "Utilisateurs",
    "abbrev": "Abréviation",
    "test_menu": "Menu de test",
    "config": "Configuration",
    "currency": "Devise",
    "credit_allowed": "Crédit autorisé",
    "allowed_for_credit": "Autorisé pour le crédit",
    "tin_or_arn": "NIF ou ARN",
    "npid": "NPID",
    "contact": "Contact",
    "speed_dial": "Composeur rapide",
    "fee_schedule": "Barème des honoraires",
    "dashboard": "Tableau de bord",
    "select_agency": "Sélectionner une agence",
    "select_department": "Sélectionner un département",
    "edit_department": "Modifier le département",
    "edit_agency": "Modifier l'entreprise cliente",
    "agency_detail": "Détail de l'agence",
    "select_role": "Sélectionner un rôle",
    "select_location": "Sélectionner un emplacement",
    "departments": "Départements",
    "agency_definition": "Définition de l'agence",
    "discontinued_test_results": "Résultats des tests discontinués",
    "assign_test_to_agency": "Attribuer un test à une agence",
    "branch_look_up": "Recherche de succursale",
    "test_definition": "Définition du test",
    "core_lab": "Laboratoire central",
    "tb_micro_pending": "TB/Micro en attente",
    "place_order": "Passer commande",
    "orderable": "Commandable",
    "none_orderable": "Non commandable",
    "pick_up_request": "Demande de ramassage",
    "time_line": "Chronologie",
    "order_status": "État de la commande",
    "all": "Tout",
    "results": "Résultats",
    "completed": "Terminé",
    "incomplete": "Incomplet",
    "micro_patho_result": "Résultat micro-patho",
    "comming_soon": "Prochainement",
    "pannic_results": "Résultats de panique",
    "confidential_results": "Résultats confidentiels",
    "commulative_sum_report": "Rapport de somme cumulative",
    "faulty_pdf": "PDF défectueux",
    "upload_results": "Télécharger les résultats",
    "upload_csv_results": "Télécharger les résultats CSV",
    "upload_financial_report": "Télécharger le rapport financier",
    "corrected_results": "Résultats corrigés",
    "pending": "En attente",
    "approved": "Approuvé",
    "declined": "Refusé",
    "daily_released_tests": "Tests libérés quotidiens",
    "single_daily_released_tests": "Tests libérés quotidiennement individuels",
    "daily_department_released_tests": "Tests libérés quotidiennement par département",
    "order_to_be_sent": "Commande à envoyer",
    "stat_orders": "Commandes statistiques",
    "referal_orders": "Commandes de référence",
    "referable" : "Référençable",
    "sample_processing_in_lab": "Traitement des échantillons en laboratoire",
    "rejected": "Rejeté",
    "tests_per_technician": "Tests par technicien",
    "daily_financial_summary": "Résumé financier quotidien",
    "specimen_tracking": "Suivi des spécimens",
    "top_branches_for_walkin_customers": "Principales succursales pour les clients sans rendez-vous",
    "released_after_tat":"Libéré après TAT",
    "tat_routine_report": "Rapport de routine TAT",
    "released_pannels_for_icl":"Panels libérés pour ICL",
    "top_agencies":"Meilleures agences",
    "top_tests":"Meilleurs tests",
    "top_orders":"Meilleures commandes",
    "credit_invoice":"Facture de crédit",
    "financial":"Financier",
    "tat_approaching": "TAT approchant",
    "submit" : "Soumettre",
    "test_results" : "Résultats des tests",
    "released_tests" : "Tests libérés",
    "pending_tests" : "Tests en attente",
    "panic_tests" : "Tests de panique",
    "uncommunicated_panic" : "Panic non communiqué",
    "average_tat" : "TAT moyen",
    "average_release_time" : "Temps moyen de libération",
    "average_delayed_tests" : "Tests retardés en moyenne",
    "total_orders" : "Total des commandes",
    "total_income" : "Revenu total",
    "total_patients" : "Total des patients",
    "order_statistics" : "Statistiques de commande",
    "agency" : "Agence",
    "walkin" : "Entrée libre",
    "payment" : "Paiement",
    "view_detail" : "Voir les détails",
    "you_must_select_agency" : "Vous devez sélectionner une agence",
    "edit" : "Modifier",
    "en" : "fr",
    "delete" : "Supprimer",
    "available" : "Disponible",
    "not_available" : "Non disponible",
    "sample_id" : "ID de l'échantillon",
    "name" : "Nom",
    "agency_id" : "ID de l'agence",
    "agency_number" : "Numéro de l'agence",
    "status" : "Statut",
    "apply_filter" : "Appliquer le filtre",
    "reset" : "Réinitialiser",
    "patient_id" : "ID du patient",
    "email" : "Email",
    "firstname" : "Prénom",
    "lastname" : "Nom de famille",
    "sex" : "Sexe",
    "date_of_birth" : "Date de naissance",
    "phone_number" : "Téléphone",
    "location" : "Emplacement",
    "username" : "Nom d'utilisateur",
    "active" : "Actif",
    "actions" : "Actions",
    "user_detail" : "Détail de l'utilisateur",
    "inactive" : "Inactif",
    "search" : "Rechercher",
    "filter" : "Filtrer",
    "add_new_user" : "Ajouter un nouvel utilisateur",
    "add_new_department" : "Ajouter un nouveau département",
    "add_client_company" : "Ajouter une entreprise cliente",
    "choose_agency_first" : "Choisissez d'abord une agence",
    "edit_user" : "Modifier l'utilisateur",
    "yes" : "Oui",
    "no" : "Non",
    "notification" : "Notifications",
    "dark" : "Sombre",
    "light" : "Clair",
    "cashier_location" : "Emplacement de la caisse",
    "city" : "Ville",
    "cashier_code" : "Code de caissier",
    "code" : "Code",
    "password" : "Mot de passe",
    "is_icl_user" : "Est un utilisateur ICL",
    "are_you_sure" : "Êtes-vous sûr de vouloir supprimer ceci ?",
    "copyright" : "Droits d'auteur",
    "icl_all_right_reserved" : " Tous droits réservés",
    "permissions": "Permissions",
    "add_role": "Ajouter un rôle",
    "add_new_role": "Ajouter un nouveau rôle",
    "select_permission": "Sélectionner les permissions",
    "enter_name": "Entrez le nom",
    "edit_permission_for": "Modifier les permissions pour",
    "role_detail": "Détail du rôle",
    "test_name" : "Nom du test",
    "referral" : "Référence",
    "success" : "Succès",
    "error" : "Erreur",
    "temporarly_discontinued_test" : "Test temporairement interrompu",
    "sample_type" : "Type d'échantillon",
    "sample_volume" : "Volume de l'échantillon",
    "sample_container" : "Contenant d'échantillon",
    "price" : "Prix",
    "non_orderable" : "Non commandable",
    "add_new_test" : "Ajouter un nouveau test",
    "test_schedule" : "Programme de test",
    "update_stat_and_urgent _price" : "Mettre à jour le prix statistique et urgent",
    "stat_price" : "Prix statistique",
    "urgent_price" : "Prix urgent",
    "field_is_required" : "Ce champ est obligatoire",
    "updating" : "Mise à jour en cours",
    "update" : "Mettre à jour",
    "enable_edit" : "Activer la modification",
    "test_abbreviation" : "Abréviation du test",
    "test_full_name" : "Nom complet du test",
    "number_of_tests" : "Nombre de tests",
    "department_name" : "Nom du département",
    "price_etb_agency_a" : "Prix ETB Agence A",
    "price_etb_agency_b" : "Prix ETB Agence B",
    "price_etb_agency_c" : "Prix ETB Agence C",
    "price_etb_agency_d" : "Prix ETB Agence D",
    "price_etb_agency_e" : "Prix ETB Agence E",
    "price_etb_agency_f" : "Prix ETB Agence F",
    "price_etb_agency_g" : "Prix ETB Agence G",
    "price_etb_agency_h" : "Prix ETB Agence H",
    "price_usd_agency_a" : "Prix USD Agence A",
    "price_usd_agency_b" : "Prix USD Agence B",
    "price_usd_agency_c" : "Prix USD Agence C",
    "price_usd_agency_d" : "Prix USD Agence D",
    "price_usd_agency_e" : "Prix USD Agence E",
    "price_usd_agency_f" : "Prix USD Agence F",
    "price_usd_agency_g" : "Prix USD Agence G",
    "tat_urgent" : "Tat Urgent",
    "tat_stat" : "Tat Stat",
    "ignorable_tat" : "Tat ignorable",
    "fake_department" : "Département fictif",
    "inperson_delivery" : "Livraison en personne",
    "currently_available" : "Actuellement disponible",
    "panel_number" : "Numéro de panel",
    "charge_code" : "Code de charge",
    "test_catagory" : "Catégorie de test",
    "test_sub_category" : "Sous-catégorie de test",
    "test_description" : "Description du test",
    "price_etb" : "Prix (ETB)",
    "price_usd" : "Prix (USD)",
    "standard_test_name" : "Nom standard du test",
    "standard_department_name" : "Nom standard du département",
    "standard_abbreviation" : "Abréviation standard",
    "test_methodology" : "Méthodologie du test",
    "estimated_result_time_in_hour" : "Temps estimé de résultat en heure",
    "test_done_abroad" : "Test effectué à l'étranger",
    "is_ihc_est" : "Est un test IHC",
    "constant_only_for_pathology" : "Constante (Uniquement pour la pathologie)",
    "walking_customer_price_usd" : "Prix pour le client qui se déplace (USD)",
    "walking_customer_price_etb" : "Prix pour le client qui se déplace (ETB)",
    "are_you_sure_delete_this_test" : "Êtes-vous sûr de vouloir supprimer ce test ?",
    "department_code" : "Code de département",
    "has_custom_data" : "Possède des données personnalisées",
    "in_person_delivery" : "Livraison en personne",
    "icl_branch" : "Branche ICL",
    "not_icl_branch" : "Non branche ICL",
    "parent" : "Parent",
    "clear" : "Effacer",
    "signout" : "Déconnexion",
    "main": "Principal",
    "additional_info": "Informations supplémentaires",
    "my_lab": "Mon laboratoire",
    "pathology": "Pathologie",
    "mrn_no": "Numéro MRN",
    "first_name": "Prénom",
    "middle_name": "Deuxième prénom",
    "last_name": "Nom de famille",
    "phone": "Téléphone",
    "primary_doctor": "Médecin principal",
    "secondary_doctor": "Médecin secondaire",
    "test_performed_lab": "Laboratoire ayant réalisé le test",
    "select_lab": "Sélectionner le laboratoire",
    "phone_alt": "Téléphone alternatif",
    "region": "Région",
    "street": "Rue",
    "passport_no": "Numéro de passeport",
    "select_insurance": "Sélectionner l'assurance",
    "member_id": "Identifiant membre",
    "did_you_have_a_meal_today": "Avez-vous pris un repas aujourd'hui ?",
    "have_you_exercised_today": "Avez-vous fait de l'exercice aujourd'hui ?",
    "exercise_regularly": "Faites-vous régulièrement de l'exercice ?",
    "how_many_times_do_you_excesise_per_week": "Combien de fois par semaine faites-vous de l'exercice ?",
    "how_long_excercise": "Combien de minutes dure votre exercice ?",
    "excercise_grade": "Comment évalueriez-vous principalement votre exercice ?",
    "feel_stressed": "Vous sentez-vous stressé(e) ?",
    "professional_help": "Avez-vous déjà demandé l'aide d'un professionnel pour gérer le stress ?",
    "sleep_duration":"Combien d'heures par jour dormez-vous ?",
    "caffine_consumption_daily": "Combien de tasses de café ou de thé consommez-vous normalement par jour ?",
    "daily_water_intake": "Combien de litres d'eau buvez-vous normalement par jour ?",
    "daily_fruit_intake": "Obtenez-vous 3 à 5 portions de fruits et légumes par jour ?",
    "usually":"D'habitude,",
    "smoke_status": "Fumez-vous ?",
    "somoke_other_than_cigar": "Fumez-vous autre chose que des cigares ?",
    "alcohol_consumption": "Consommez-vous de l'alcool ?",
    "work_nature": "Comment décririez-vous votre travail ?",
    "residence": "Résidence ?",
    "travel_status":"Voyagez-vous ?",
    "dm_family_history": "Avez-vous des antécédents familiaux de diabète sucré ?",
    "htn_family_history": "Avez-vous des antécédents familiaux d'hypertension ?",
    "cancer_family_history": "Avez-vous des antécédents familiaux de cancer ?",
    "education_level": "Niveau d'éducation",
    "pregnancy": "Êtes-vous enceinte ?",
    "breast_feeding_status": "Êtes-vous en train d'allaiter ?",
    "contraception_use":"Utilisez-vous des contraceptifs hormonaux ?",
    "less_than": "Moins de",
    "minutes": "Minutes",
    "more": "Plus",
    "and": "Et",
    "simple": "Léger",
    "moderate": "Modéré",
    "vigorous": "Vigoureux",
    "hours": "Heures",
    "eat_out": "Manger dehors",
    "cook_at_home": "Cuisiner à la maison",
    "work_nature_1": "Travail de bureau principalement assis",
    "work_nature_2": "Travail de bureau mais nécessitant beaucoup de mouvement/déplacements",
    "work_nature_3": "Travail physique légalement debout",
    "urban": "Urbain",
    "rural": "Rural",
    "suburban": "Banlieue",
    "travel_by_private":"En voiture privée",
    "walk": "Marcher",
    "read_write": "Lire et écrire",
    "secondary_school": "École secondaire",
    "illiterate": "Illettré",
    "primary_school": "École primaire",
    "college_and_above":"Collège et plus",
    "search_pannel": "Rechercher des panneaux par nom, code ....",
    "search_test_pannels": "Rechercher des panneaux de test",
    "clinical_info": "Informations cliniques",
    "relevant_history": "Historique pertinent",
    "relevant_image": "Image pertinente",
    "relevant_lab": "Travail de laboratoire pertinent",
    "enter_relevant_info":"Entrer les informations pertinentes de l'historique",
    "enter_relevant_image_info": "Entrer les informations pertinentes de l'image",
    "enter_relevant_lab_work_info":"Entrer les informations pertinentes du travail de laboratoire",
    "specimen_class": "Classe d'échantillon",
    "biopsy_type": "Type de biopsie",
    "action": "Action",
    "urgent_order": "Commande urgente",
    "search_here": "Rechercher ici",
    "patient_search": "Recherche de patient",
    "test_code": "Code de test",
    "default_price": "Prix par défaut",
    "schedule": "Horaire",
    "add_to_table": "Ajouter au tableau",
    "male": "Homme",
    "female": "Femme",
    "order_summary": "Résumé de la commande",
    "full_name": "Nom complet",
    "total_number_of_tests":"Nombre total de tests",
    "total_price": "Prix total",
    "list_of_sample_container": "Liste des contenants d'échantillons",
    "show_printable_order_summary": "Afficher le résumé de commande imprimable",
    "save": "Enregistrer",
    "order": "Commander",
    "cancel": "Annuler",
    "costs": "Cela vous coûtera ",
    "provide_additional_patho_info": "Le test de pathologie a été sélectionné. Vous devrez remplir des informations supplémentaires sur la pathologie",
    "field_required": "Champ requis",
    "okay": "D'accord",
    "transaction_response":"Réponse de transaction",
    "cashier_summary":"Résumé de commande pour le caissier",
    "confirm": "Confirmer",
    "print_cash_receipt":"Imprimer le reçu en espèces",
    "pt_agreed": "Patient Agreed",
    "reciept_no": "Reçu / Numéro FS",
    "add_new_branch" : "Ajouter une nouvelle succursale",
    "branch_name" : "Nom de la succursale",
    "concatenated_code": "Code Concaténé",
    "branch_code": "Code de la succursale",
    "branch_description": "Description de la succursale",
    "telephone_1": "Téléphone 1",
    "telephone_2": "Téléphone 2",
    "sample_collection_only": "Collecte d'échantillons uniquement",
    "covid_test_provided": "Test Covid Fourni",
    "wellness_test_provided": "Test de bien-être fourni",
    "speed_dial_provider": "Fournisseur de numérotation rapide",
    "open_on_holiday": "Ouvert les jours fériés",
    "open_every_time": "Ouvert à chaque fois",
    "visible_to_public": "Visible au public",
    "allowed_for_coupon": "Autorisé pour le coupon",
    "edit_branch_look_up": "Modifier la recherche de succursales",
    "add_branch_look_up": "Ajouter une nouvelle recherche de succursales",
    "view_branch_look_up_detail": "Détail de la recherche de succursales",
    "panel_summary": "Résumé du panneau",
    "are_you_sure_this_panels_are_fore": "Êtes-vous sûr que ces panneaux sont pour",
    "other": "Autre",
    "send_pickup_request": "Envoyer une demande de ramassage",
    "sending": "Envoi en cours...",
    "search_historical_record": "Rechercher un enregistrement historique",
    "fullname": "Nom complet",
    "cardnumber": "Numéro de carte",
    "urgency": "Urgence",
    "age": "Âge",
    "panic_result": "Résultat de panique",
    "spoiled_samples": "Échantillons gâtés",
    "approval_status": "Statut d'approbation",
    "ordered_time": "Heure de commande",
    "downloading": "Téléchargement",
    "download_existing": "Télécharger l'existant",
    "only_csv_files_are_allowed": "Seuls les fichiers CSV sont autorisés",
    "select_category": "Sélectionner une catégorie",
    "month": "Mois",
    "add_to_list": "Ajouter à la liste",
    "select_file": "Sélectionner le fichier",
    "total_tests": "Total des tests",
    "total_discrepancy": "Discrepance totale",
    "received_time": "Heure de réception",
    "released_time": "Heure de libération",
    "pdf": "PDF",
    "flag": "Drapeau",
    "new_time": "Nouvelle heure",
    "discrepancy_detail": "Détail de la discrepancy",
    "technician": "Technicien",
    "inserted_date": "Date d'insertion",
    "test_count": "Nombre de tests",
    "type_of_report": "Type de rapport",
    "invoice_summary": "Résumé de la facture",
    "invoice_attachment": "Pièces jointes de la facture",
    "statement_of_account": "Relevé de compte",
    "only_pdf_file_is_allowed": "Seul le fichier PDF est autorisé",
    "cashier_name": "Nom du caissier",
    "fs_number": "Numéro FS",
    "payment_type": "Type de paiement",
    "date_time": "Date et heure",
    "export": "Exporter",
    "sample_tube": "Tube d'échantillon",
    "comment": "Commentaire",
    "remark": "Remarque",
    "enter_comment": "Entrez un commentaire",
    "phlebotomy_comment": "Commentaire de la phlébotomie",
    "draw_location": "Emplacement du prélèvement",
    "upload_by": "Téléchargé par",
    "uploaded_at": "Téléchargé à",
    "media_type": "Type de média",
    "select_media_type": "Sélectionner le type de média",
    "only_jpg_jpeg_png_and_pdf_file_is_allowed": "Seuls les fichiers jpg jpeg png et pdf sont autorisés",
    "logistics" : "Logistique",
	"selectcashier" : "sélectionner le caissier",
    "user_type" :"Type d'utilisateur",
	"specimen_managment" : "Gestion des spécimens"
} 