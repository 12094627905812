import { CURRENT_USER, USER_PREFERENCE } from "redux/constants/Auth";
var listOfPermissions = [];
export const getTheCurrentUserPermissions = () => {
  const currentUser = getParsedCurrentUser();
  //const currentUser = ["view-testDirectories", "view-neworders",  "view-pending-discrepancies", "approve-discrepancy", "decline-discrepancy"];
  return currentUser?.permissions;
};

export const DoesThisUserHasAPermissionToThisComponent = (component_permission) => {
  if(listOfPermissions.length > 0){
   //return listOfPermissions.length <= 0 ? false :  listOfPermissions.includes(component_permission, 0);
   //console.log(listOfPermissions, "listOfPermissions from the if clause")
  //  console.log(listOfPermissions.includes(component_permission, 0), "listOfPermissions from the if clause")
    return listOfPermissions.includes(component_permission, 0);
  }
  else {
    const user_permissions = getTheCurrentUserPermissions();
    const current_user_permission = user_permissions !== undefined ? user_permissions.map( item =>{
       return item.permission_name;
    }) : [];
    listOfPermissions = current_user_permission;
    return current_user_permission.length <= 0 ? false : current_user_permission.includes(component_permission, 0);
  }
};

export const getCurrentRoleName = () =>{
   return getParsedCurrentUser().name;
}

export const getCurrentRole = () =>{
  return getParsedCurrentUser().role;
}

function getParsedCurrentUser()
{
  return JSON.parse(localStorage.getItem(CURRENT_USER));
}

export const doesThisUserIsAgency = ()=>{
   let currentUser = getParsedCurrentUser();
   return currentUser["client_company"] !== null ? true : false;
}

export const doesThisUserIsIclUser =() =>{
  if(!getParsedCurrentUser())
  {
     return 0;
  }
  else {
    return getParsedCurrentUser()['icl_user'] === 1 ? 1 : 0;
  }
}

export const doesThisUserNotIclUser =() =>{
  return  doesThisUserIsIclUser() ? 0 : 1;
}

export const getUserCommunicationMediaType = () =>{
  return getParsedCurrentUser()['communication_media_type'] ?? "Unknown"
}

export const getUserPreference = () =>{
  // return getParsedCurrentUser()['general_user_preference'] ?? "Unknown";
  // alert(JSON.stringify(localStorage.getItem(USER_PREFERENCE)));
//  return 1;
  return   JSON.parse( localStorage.getItem(USER_PREFERENCE) ?? []);
}

export const getCashierLocation = () =>{
   return getParsedCurrentUser()['user_location'] ?? "Unknown";
}

export const getCurrentUserName = () =>{
   return getParsedCurrentUser()['name'] ?? "Unknown";
}

export const getCurrentUserId = () =>{
  return getParsedCurrentUser()['id'];
}

export const doesThisUserIsSrd = () => {
  
  return  getParsedCurrentUser() ?  getParsedCurrentUser()['user_type'] === "srd" : false;
}

export const doesThisUserIsCashier = () =>{
  return getParsedCurrentUser()['user_type'] === "cashier" || getParsedCurrentUser()['user_type'] === "srd";
}

export const doesThisUserIsPhlebotomy = () =>{
  return getParsedCurrentUser()['user_type'] === "phlebotomy";
}

export const doesThisUserIsLabTechnician = () =>{
  return getParsedCurrentUser()['user_type'] === "lab";
}

export const doesThisUserIsFinance = () =>{
  return getParsedCurrentUser()['user_type'] === "finance";
}

export const doesThisUserIsAdmin = () =>{
  return getParsedCurrentUser()['user_type'] === "admin";
}

export const doesThisUserIsDoctor = () =>{
  return getParsedCurrentUser()['user_type'] === "doctor";
}

export const doesThisUserIsAgencyClientUser = () =>{
  return getParsedCurrentUser()['user_type'] === "agencyclientuser";
}