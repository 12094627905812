import {
  DELETE_NEW_ORDER_FAILURE,
  DELETE_NEW_ORDER_REQUEST,
  DELETE_NEW_ORDER_SUCCESS,
  FETCH_NEW_ORDER_TESTS_FAILURE,
  FETCH_NEW_ORDER_TESTS_REQUEST,
  FETCH_NEW_ORDER_TESTS_SUCCESS,
  FETCH_LOCATION_LOOKUP_FAILURE,
  FETCH_LOCATION_LOOKUP_REQUEST,
  FETCH_LOCATION_LOOKUP_SUCCESS,
  CREATE_NEW_ORDER_FAILURE,
  CREATE_NEW_ORDER_REQUEST,
  CREATE_NEW_ORDER_SUCCESS,
  UPDATE_NEW_ORDER_FAILURE,
  UPDATE_NEW_ORDER_REQUEST,
  UPDATE_NEW_ORDER_SUCCESS,
  SET_NEW_ORDER_VALIDATION_ERRORS,
  FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST,
  FETCH_EXISTING_NEW_ORDER_PATIENT_SUCCESS,
  FETCH_EXISTING_NEW_ORDER_PATIENT_FAILURE,
  SHOW_NEW_ORDER_SEARCH_FORM,
  FETCH_ZONE_FAILURE,
  FETCH_ZONE_REQUEST,
  FETCH_ZONE_SUCCESS,
  FETCH_WOREDA_FAILURE,
  FETCH_WOREDA_REQUEST,
  FETCH_WOREDA_SUCCESS,
  SHOW_PAYMENT_DRAWER,
  CLOSE_PAYMENT_DRAWER,
  UPDATE_CS_OR_FS_NUMBER_REQUEST,
  UPDATE_CS_OR_FS_NUMBER_SUCCESS,
  UPDATE_CS_OR_FS_NUMBER_FAILURE
} from "constants/NewOrder";

export const fetchNewOrderTestRequest = (Agency) => ({
  type: FETCH_NEW_ORDER_TESTS_REQUEST,
  payload: Agency
});

export const fetchNewOrderTestSuccess = (newOrdertests) => ({
  type: FETCH_NEW_ORDER_TESTS_SUCCESS,
  payload: newOrdertests,
});

export const fetchLocationLookUpFailure = (error) => ({
  type: FETCH_LOCATION_LOOKUP_FAILURE,
  payload: error,
});

export const fetchLocationLookUpRequest = () => ({
  type: FETCH_LOCATION_LOOKUP_REQUEST,
});

export const fetchLocationLookUpSuccess = (location) => ({
  type: FETCH_LOCATION_LOOKUP_SUCCESS,
  payload: location,
});

export const fetchzoneFailure = (error) => ({
  type: FETCH_ZONE_FAILURE,
  payload: error,
});

export const fetchzoneRequest = (parentid) => ({
  type: FETCH_ZONE_REQUEST,
  payload: parentid,
});

export const fetchzoneSuccess = (zones) => ({
  type: FETCH_ZONE_SUCCESS,
  payload: zones,
});

export const fetchworedaFailure = (error) => ({
  type: FETCH_WOREDA_FAILURE,
  payload: error,
});

export const fetchworedaRequest = (parentid) => ({
  type: FETCH_WOREDA_REQUEST,
  payload: parentid,
});

export const fetchworedaSuccess = (woreda) => ({
  type: FETCH_WOREDA_SUCCESS,
  payload: woreda,
});

export const fetchNewOrderTestFailure = (error) => ({
  type: FETCH_NEW_ORDER_TESTS_FAILURE,
  payload: error,
});

export const setNewOrderValidationErrors = (validationErrors) => ({
  type: SET_NEW_ORDER_VALIDATION_ERRORS,
  payload: validationErrors,
});

export const fetchNewOrderPatientsRequest = (startDate,
  endDate,searchKey ,mrnnumber, phonenumber, fullname, patientid, sampleid, searchtype="without_order_and_result") => ({
  type: FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST,
  payload: {
    startDate,endDate,searchKey ,mrnnumber,phonenumber, fullname, patientid, sampleid, searchtype
  }
});

export const fetchNewOrderPatientsSuccess = (payload) => ({
  type: FETCH_EXISTING_NEW_ORDER_PATIENT_SUCCESS,
  payload: payload,
});

export const fetchNewOrderPatientsFailure = (error) => ({
  type: FETCH_EXISTING_NEW_ORDER_PATIENT_FAILURE,
  payload: error,
});

export const toggleNewOrderPatientSearchForm = () => ({
  type: SHOW_NEW_ORDER_SEARCH_FORM,
});

export const deleteNewOrderRequest = (id) => ({
  type: DELETE_NEW_ORDER_REQUEST,
  payload: id,
});

export const deleteNewOrderSuccess = (newOrder) => ({
  type: DELETE_NEW_ORDER_SUCCESS,
  payload: newOrder,
});

export const deleteNewOrderFailure = (error) => ({
  type: DELETE_NEW_ORDER_FAILURE,
  payload: error,
});

export const createNewOrderRequest = (newOrder, doesThePdfAllowedToBeOpened, sampleManagementData, pdfType) => ({
  type: CREATE_NEW_ORDER_REQUEST,
  payload: {
    newOrder,
    doesThePdfAllowedToBeOpened,
    sampleManagementData,
    pdfType
  }
});

export const createNewOrderSuccess = (payload) => ({
  type: CREATE_NEW_ORDER_SUCCESS,
  payload: payload,
});

export const createNewOrderFailure = (error) => ({
  type: CREATE_NEW_ORDER_FAILURE,
  payload: error,
});


export const updateNewOrderRequest = (testOrder) => ({
  type: UPDATE_NEW_ORDER_REQUEST,
  payload: testOrder,
});

export const updateNewOrderSuccess = (testOrder) => ({
  type: UPDATE_NEW_ORDER_SUCCESS,
  payload: testOrder,
});

export const updateNewOrderFailure = (error) => ({
  type: UPDATE_NEW_ORDER_FAILURE,
  payload: error,
});

export const updateCsOrFsNumberRequest =(payload, pdfType) =>({
  type : UPDATE_CS_OR_FS_NUMBER_REQUEST,
  payload : {
    payload,
    pdfType
  } 
})

export const updateCsOrFsNumberSuccess = (payload) =>({
  type : UPDATE_CS_OR_FS_NUMBER_SUCCESS,
  payload : payload
})

export const updateCsOrFsNumberFailure = (payload) =>({
  type : UPDATE_CS_OR_FS_NUMBER_FAILURE,
  payload : payload
})

export const showPaymentDrawer = () => ({
  type: SHOW_PAYMENT_DRAWER
})

export const closePaymentDrawer = () => ({
  type: CLOSE_PAYMENT_DRAWER
})
