import {
  FETCH_WEBLEDGER_SUCCESS,
  FETCH_WEBLEDGER_REQUEST,
  FETCH_WEBLEDGER_FAILURE,
  UPDATE_WEBLEDGER_REQUEST,
  UPDATE_WEBLEDGER_SUCCESS,
  UPDATE_WEBLEDGER_FAILURE,
  RESER_WEBLEDGER

} from "constants/WebLedgerInfo";

const initState = {
  loading: false,
  updating: false,
  list: [],
  errorMessage: "",
};

const fetchwebledgerresults = (state = initState, action) => {
  switch (action.type) {
    case FETCH_WEBLEDGER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_WEBLEDGER_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_WEBLEDGER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
      case UPDATE_WEBLEDGER_REQUEST:
        return {
          ...state,
          updating: true,
          errorMessage: "",
          validationErrors: [],
        };
      case UPDATE_WEBLEDGER_SUCCESS:
        return {
          ...state,
          updating: false,
          list:action.payload,
          errorMessage: "",
          validationErrors: [],
          showSearchForm: false,
        };
      case UPDATE_WEBLEDGER_FAILURE:
        return {
          ...state,
          updating: false,
          errorMessage: action.payload,
          validationErrors: [],
        };
        case RESER_WEBLEDGER:
          return {
            ...state,
            loading: false,
            updating: false,
            list: [],
            errorMessage: "",
          };
    default:
      return state;
  }
};

export default fetchwebledgerresults;
