import fetch from "auth/FetchInterceptor";

const DoctorsList = {};

DoctorsList.get = function (payload) {
  // console.log("get - saga");
  return fetch({
    url : "/getDoctorsList",
  });
};

export default DoctorsList;