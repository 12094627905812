import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import { CREATE_ROLE_REQUEST, FETCH_ROLES_REQUEST, UPDATE_ROLE_REQUEST } from "constants/Role";
import {
  createRoleFailure,
  createRoleSuccess,
  fetchRolesFailure,
  fetchRolesSuccess,
  setRoleValidationErrors,
  updateRoleFailure,
  updateRoleSuccess,
} from "redux/actions";
import RoleService from "services/Role";

export function* onFetchRoles() {
  yield takeLatest(FETCH_ROLES_REQUEST, function* () {
    try {
      const result = yield call(RoleService.index);
      if (result.status) {
        yield put(fetchRolesSuccess(result.data));
      } else {
        yield put(fetchRolesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchRolesFailure(error.message));
    }
  });
}

export function* onUpdateRole() {
  yield takeLatest(UPDATE_ROLE_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(RoleService.update, payload.id, payload);
      if (result.status) {
        message.success("Updated");
        yield put(updateRoleSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Error updating the role");
        yield put(setRoleValidationErrors(result.data.errors));
        yield put(updateRoleFailure(result.message));
      } 
    //    else if (result.code === PERMISSION_DENIED_CODE) {
    //    message.error("You don't have the right permission");
    //   yield put(updateRoleFailure(result.message));
    // }
    else {
        yield put(updateRoleFailure(result.message));
      }
    } catch (error) {
      yield put(updateRoleFailure(error.message));
    }
  });
}

export function* onCreateRole() {
  yield takeLatest(CREATE_ROLE_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(RoleService.create, payload);
      if (result.status) {
        message.success("Created");
        yield put(createRoleSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Please check your input");
        yield put(setRoleValidationErrors(result.data.errors));
        yield put(createRoleFailure(result.message));
      } else {
        yield put(createRoleFailure(result.message));
      }
    } catch (error) {
      yield put(createRoleFailure(error.message));
    }
  });
}


export default function* rootSaga() {
  yield all([fork(onFetchRoles), fork(onCreateRole), fork(onUpdateRole)]);
}
