import{
    FETCH_DOCTORS_LIST_REQUEST,
    FETCH_DOCTORS_LIST_SUCCESS,
    FETCH_DOCTORS_LIST_FAILURE,
}from "constants/doctors_list";

export const fetchDoctorsListRequest = () =>({
    type : FETCH_DOCTORS_LIST_REQUEST,
    // payload : payload
});

export const fetchDoctorsListSuccess = (payload) =>({
    type : FETCH_DOCTORS_LIST_SUCCESS,
    payload : payload
});

export const fetchDoctorsListFailure = (payload) => ({
    type : FETCH_DOCTORS_LIST_FAILURE,
    payload : payload
});