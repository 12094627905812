export const FETCH_WEBLEDGER_REQUEST = "FETCH_WEBLEDGER_REQUEST";
export const FETCH_WEBLEDGER_SUCCESS = "FETCH_WEBLEDGER_SUCCESS";
export const FETCH_WEBLEDGER_FAILURE = "FETCH_WEBLEDGER_FAILURE";

export const UPDATE_WEBLEDGER_REQUEST = "UPDATE_WEBLEDGER_REQUEST";
export const UPDATE_WEBLEDGER_SUCCESS = "UPDATE_WEBLEDGER_SUCCESS";
export const UPDATE_WEBLEDGER_FAILURE = "UPDATE_WEBLEDGER_FAILURE";
export const RESER_WEBLEDGER = "RESER_WEBLEDGER";


