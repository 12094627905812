import fetch from "auth/FetchInterceptor";

const TestRegistrationService = {};

TestRegistrationService.index = function () {
  return fetch({
    url: "/test-registrations",
    method: "get",
  });
};

TestRegistrationService.create = function (data) {
  return fetch({
    url: "/updatePanicLedger",
    method: "post",
    data,
  });
};

TestRegistrationService.update = function (id, data) {
  return fetch({
    url: `/test-registrations/${id}`,
    method: "put",
    data,
  });
};

TestRegistrationService.delete = function (id) {
  return fetch({
    url: `/test-registrations/${id}`,
    method: "delete",
  });
};

export default TestRegistrationService;