import _ from "lodash"
  export const updateOrder = (state, action) => {
    let list = state.list;
    const id = action.payload[0].uuid;
  
    const index = _.findIndex(list, ["uuid", id]);
    list[index] = action.payload[0];
    return list;
  };

  export const deleteOrderAllPatient = (state, action) =>{
    let list = state.list;
    const sampleId = action.payload['webSampleId'];
    let temp = list.filter((row) => row.webSampleId !== sampleId);
    return temp;
  }

  export const updatePatientDemographySuccess = (state, action) =>{
    
      let list = state.list;
      const sample_id = action.payload['webSampleId'];
      const index = _.findIndex(list, ["webSampleId", sample_id]);

      if (index !== -1) {
        
      let temp = list [index];
      temp['webFullName']  = action.payload['webFirstName'] + " " + action.payload['webMiddleName'] + " " + action.payload['webLastName'];
      temp['webFirstName'] =  action.payload['webFirstName'];
      temp['webMiddleName'] =  action.payload['webMiddleName'];
      temp['webLastName'] =  action.payload['webLastName'];
      temp['webEmail'] =  action.payload['webEmail'];
      temp['webPhoneNumber'] =  action.payload['webPhoneNumber'];
      temp['webPhoneNumberSecondary'] =  action.payload['webPhoneNumberSecondary'];
      temp['pidFsNumber'] =  action.payload['webCsOrFsNumber'];
      list[index] = temp;

    } else {
      console.error(`No item found with webSampleId: ${sample_id}`);
  }


      return list.map((row) => row);
  }