import { MAX_GRAPH_TESTS } from "configs/AppConfig";
import {
  MONTH_DAY_YEAR_WHILE_MONTH_IN_STRING_FORMAT,
  DATE_IN_NUMBER_FORMAT,
  DAY_MONTH_YEAR_HOUR_MINUTE_WHILE_BOTH_DAY_AND_MONTH_ARE_STRING_FORMAT,
} from "constants/DateTimeUtilConstants";
import { changeDateFormatAndReturnNewDate } from "./DateTimeUtil";

export const getPatientObxGraphResult = (results, patientFullName) => {
  // let NUMBER_OF_ROWS_OF_GRAPH = 10;
  let NUMBER_OF_COLUMNS_OF_GRAPH = 24;

  // let jsonArray = [
  //    {"id": 0, "test_obx": "CBC", "value": -7.2,   "date_collected":"202303041612"},
  //   {"id": 1, "test_obx": "CBC", "value": 10,  "date_collected":"202309021600"},
  //   {"id": 11, "test_obx": "CBC", "value": null, "date_collected":"202209021600"},
  //   {"id": 12, "test_obx": "CBC", "value": ">35", "date_collected":"202206021600"},
  //   {"id": 13, "test_obx": "CBC", "value": "Unknown", "date_collected":"202208021600"},
  //   {"id": 14, "test_obx": "CBC", "value": "<23", "date_collected":"202108021600"},
  //   {"id": 2, "test_obx": "Blood", "value": 5, "date_collected":"202301021100"},
  //   {"id": 4, "test_obx": "CBC", "value": 15, "date_collected":"202302020123"},
  //   {"id": 5, "test_obx": "HBSC", "value": "20", "date_collected":"202101131659"},
  //   {"id": 6, "test_obx": "CBC", "value": 15,  "date_collected":"202301091657"},
  //   {"id": 7, "test_obx": "HBSC", "value": 8,  "date_collected":"202201131657"},
  //   {"id": 8, "test_obx": "Blood", "value": 12,  "date_collected":"202301122359"},
  //   {"id": 9, "test_obx": "Blood", "value": "some-inva",  "date_collected":"202301021659"},
  //   {"id": 10, "test_obx": "HCG", "value": "z1",  "date_collected":"202301021659"},
  //   {"id": 10, "test_obx": "HCG", "value": "z2",  "date_collected":"202303021659"},
  //   {"id": 10, "test_obx": "HCG", "value": "z3", "date_collected":"202307021659"}
  // ];

  //let push the the patient result to jsonArray
  let jsonArray = [];
  let testNumCounter = 0;
  results.map((innerArray) => {
    if (MAX_GRAPH_TESTS > testNumCounter) {
      jsonArray.push({
        test_obx: innerArray.obxTestName,
        value: isValidNumber(innerArray.obxResult)
          ? parseFloat(innerArray.obxResult)
          : innerArray.obxResult,
        date_collected: innerArray.insertedTimestamp,
        obxFlag: innerArray.obxFlag,
      });
    } 
    testNumCounter++;
    return "";
  });

  let ultimate_max_y = Math.max(
    ...jsonArray
      .filter((obj) => isValidNumber(obj.value))
      .map((obj) => obj.value)
  );
  let ultimate_min_y = Math.min(
    ...jsonArray
      .filter((obj) => isValidNumber(obj.value))
      .map((obj) => obj.value)
  );
  // let space_between_row = Math.floor(
  //   (ultimate_max_y - ultimate_min_y) / NUMBER_OF_ROWS_OF_GRAPH
  // );

  let ultimate_max_x = Math.max(
    ...jsonArray
      .filter((obj) => isValidNumber(obj.date_collected))
      .map((obj) => obj.date_collected)
  );
  let ultimate_min_x = Math.min(
    ...jsonArray
      .filter((obj) => isValidNumber(obj.date_collected))
      .map((obj) => obj.date_collected)
  );
  let space_between_columns = Math.floor(
    (ultimate_max_x - ultimate_min_x) / NUMBER_OF_COLUMNS_OF_GRAPH
  );

  let test_obxedArray = jsonArray.reduce((acc, obj) => {
    let key = obj.test_obx;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  let updatedGroupedArray = Object.values(test_obxedArray).map((test_obx) => {
    //let ultimate_max_y = Math.max(...data.filter(obj => typeof obj.value === "number").map(obj => obj.value));

    let test_obx_max_y = Math.max(
      ...test_obx
        .filter((obj) => typeof obj.value === "number")
        .map((obj) => obj.value)
    );
    let test_obx_min_y = Math.min(
      ...test_obx
        .filter((obj) => typeof obj.value === "number")
        .map((obj) => obj.value)
    );
    let test_obx_has_percentage =
      isValidNumber(test_obx_max_y) &&
      isValidNumber(test_obx_min_y) &&
      test_obx_max_y !== test_obx_min_y;
    let test_obx_default_percentage = Math.floor(Math.random() * 11) * 10; //it is either 0,10...90, or 100
    const colorArray = ["red", "yellow", "black", "gray"];
    const test_obx_default_color =
      colorArray[Math.floor(Math.random() * colorArray.length)];

    return test_obx.map((obj) => ({
      ...obj,
      obxValue: obj.value,
      tool_tip_display_text:
        "<b>Result:</b> " +
        obj.value +
        "</br><b>Date:</b> " +
        changeDateFormatAndReturnNewDate(
          obj.date_collected,
          MONTH_DAY_YEAR_WHILE_MONTH_IN_STRING_FORMAT
        ) +
        "</br><b>TestName:</b> " +
        obj.test_obx +
        "</br><b>Flag:</b> " +
        obj.obxFlag,
      is_numeric_data: test_obx_has_percentage && isValidNumber(obj.value),
      is_numeric_test_obx: test_obx_has_percentage,
      is_test_obx_max: obj.value === test_obx_max_y,
      is_test_obx_min: obj.value === test_obx_min_y,
      percentage:
        parseInt(
          getPercentage(
            obj.value,
            test_obx_max_y,
            test_obx_min_y,
            test_obx_default_percentage
          )
        ) === 0
          ? 10
          : parseInt(
              getPercentage(
                obj.value,
                test_obx_max_y,
                test_obx_min_y,
                test_obx_default_percentage
              )
            ),
      column_index: Math.round(
        (obj.date_collected - ultimate_min_x) / space_between_columns
      ),
      has_flag: obj.obxFlag ? true : false,
      test_obx_color: test_obx_default_color,
      test_obx_max: test_obx_max_y,
      test_obx_min: test_obx_min_y,
      ultimate_max_val_y: ultimate_max_y,
      ultimate_min_val_y: ultimate_min_y,
      ultimate_max_date_x: ultimate_max_x,
      ultimate_min_date_x: ultimate_min_x,
    }));
  });

  function isValidNumber(someVar) {
    return (
      typeof someVar === "number" ||
      (!isNaN(someVar) && !isNaN(parseFloat(someVar)))
    );
    //return ((typeof someVar === "number"));
  }

  function getPercentage(
    toCheck,
    test_obx_max_y,
    test_obx_min_y,
    test_obx_default_percentage
  ) {
    var is_max_value =
      test_obx_max_y === toCheck ||
      (toCheck && toCheck.toString().startsWith(">"));
    var is_min_value =
      test_obx_min_y === toCheck ||
      (toCheck && toCheck.toString().startsWith("<"));
    var take_default_value =
      !is_max_value && !is_min_value && !isValidNumber(toCheck);

    if (is_max_value || is_min_value || take_default_value) {
      return take_default_value
        ? test_obx_default_percentage
        : is_max_value
        ? 100
        : 0;
    } else {
      return (
        ((toCheck - test_obx_min_y) * 100) / (test_obx_max_y - test_obx_min_y)
      );
    }
  }

  /*console.log(space_between_row );
console.log(space_between_columns );
console.log(ultimate_max_y );
console.log(ultimate_min_y);
console.log(ultimate_max_x );
console.log(ultimate_min_x); */
  // console.log(updatedGroupedArray);

  //get unique date from all the tests
  var filteredDate = [];
  updatedGroupedArray.map((row) => {
   return row.map((innerRow) => {
      if (!filteredDate.includes(innerRow.date_collected)) {
        filteredDate.push(innerRow.date_collected);
      }
      return "";
    });
  }
  );

  //sort the date
  var sortedDate = filteredDate.sort((a, b) => {
    return (
      new Date(
        changeDateFormatAndReturnNewDate(
          a,
          DAY_MONTH_YEAR_HOUR_MINUTE_WHILE_BOTH_DAY_AND_MONTH_ARE_STRING_FORMAT
        )
      ) -
      new Date(
        changeDateFormatAndReturnNewDate(
          b,
          DAY_MONTH_YEAR_HOUR_MINUTE_WHILE_BOTH_DAY_AND_MONTH_ARE_STRING_FORMAT
        )
      )
    );
  });

  //now get the actual graphData. To get the actual graph data we need to make sure that
  //if the date at i of the filteredDate is present on the nested array of at jth position
  //we need to create the new json to the actual graphData with the correct date and the result
  //but if the date is not found on the jth row of the nested array then we need to add a new json data with the correct data with null date value.
  //this is all the algorithm does to create the actual graph data.
  var graphData = [];
  var patientResultContainer = [];
  updatedGroupedArray.map((row) => {
    let data = [];
    var tempToolTipContainer = [];
    var tempResultContainer = [];
    let temp1 = sortedDate.map((singleDate) => {
      let isFound = false;
      let percentage = 0;
      let tool_tip_display_text = 0;
      let obxResult = 0;
     let innerTemp =  row.map((innerRow) => {
        if (innerRow.date_collected === singleDate) {
          isFound = true;
          tool_tip_display_text = innerRow.tool_tip_display_text;
          percentage = innerRow.percentage;
          obxResult = innerRow.obxValue;
        }
        return "";
      });

      if (isFound) {
        tempToolTipContainer.push(tool_tip_display_text);
        data.push(percentage);
        tempResultContainer.push(obxResult);
      } else {
        tempToolTipContainer.push(null);
        data.push(null);
        tempResultContainer.push(null);
      }
      return innerTemp;
    });
     patientResultContainer.push(tempResultContainer);
    graphData.push({
      name: row[0].test_obx,
      data: data,
      tooltip: tempToolTipContainer,
    });
    return temp1;
  });

  filteredDate = sortedDate.map((singleDate) => {
    return changeDateFormatAndReturnNewDate(singleDate, DATE_IN_NUMBER_FORMAT);
  });

  let actualGraphData = {
    series: graphData,
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
      },
      stroke: {
        width: [5, 5, 4],
        curve: "straight",
      },
      yaxis: {
        labels: {
          show: false,
        },
        title: {
          text: "",
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return (
            patientResultContainer[opts.seriesIndex][opts.dataPointIndex] ?? ""
          ); // return the value with a dollar sign and commas separating thousands
        },
        style: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
        },
      },
      labels: filteredDate,
      title: {
        text: patientFullName + " Result in Graph",
      },
      tooltip: {
        enabled: true,
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          return `<div style="background-color: #fff; border: 1px solid #FFA500; padding: 10px 70px 10px  5px "><p class="value">${graphData[seriesIndex]["tooltip"][dataPointIndex]}</p></div>`;
          // return `<div style="background-color: #fff; border: 1px solid #FFA500; padding: 10px 70px 10px  5px "><p class="label">${w.globals.labels[dataPointIndex]}</p><p class="value">${graphData[seriesIndex]['tooltip'][dataPointIndex]}</p></div>`;
        },
      },
    },
  };

  return actualGraphData;
};
