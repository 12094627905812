import React, {useState } from 'react';
import { Menu, Dropdown, Badge, Avatar, List } from 'antd';
import { 
  BellOutlined, UserOutlined} from '@ant-design/icons';
import Flex from 'components/shared-components/Flex'
import { connect } from 'react-redux';
import { fetchAllNotificationsRequest, updateNotificationRequest } from 'redux/actions';

const getNotificationBody = list => {
  return list.length > 0 ?
  <List
    size="small"
    itemLayout="horizontal"
    dataSource={list}
    renderItem={appointment => (
      <List.Item className="list-clickable">
        <Flex alignItems="center">
        <div className="pr-3">
            <Avatar className={`ant-avatar-success`} icon={<UserOutlined />} />
          </div>
          <div className="mr-3">
            <span className="font-weight-bold text-dark">{appointment.fullName} </span>
            <span className="text-gray-light">Comming soon 🔜 </span>
          </div>
          {/* <small className="ml-auto">''</small> */}
        </Flex>
      </List.Item>
    )}
  />
  :
  <div className="empty-notification">
    <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
    <p className="mt-3">You have viewed all notifications</p>
  </div>;
}

 const NavNotification = ({ cart, language, fetchNotifications, updateNotifications}) => {

  // useEffect(()=>{
  //     fetchNotifications();
  // },[fetchNotifications]);
  
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  }


  const clearNotification = () =>{
   //now it is time to update the notification cart
   updateNotifications();
  }
  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">{language.notification}</h4>
          <h5 onClick={clearNotification} style={{cursor : 'pointer'}} className="ml-auto">{language.clear}</h5>
      </div>
      <div className="nav-notification-body">
        {getNotificationBody(cart)}
      </div>
    
    </div>
  );

  return (
    <Dropdown 
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal">
      <Menu.Item key="notification">
          <Badge count={cart.length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}

const mapStateToProps =(state) => ({
  cart: state.notifications.cart,
  language : state.theme.language
})

export const mapDispatchToProps = (dispatch) =>({
  fetchNotifications : () => dispatch(fetchAllNotificationsRequest()),
  updateNotifications : () => dispatch(updateNotificationRequest())
})
export default connect(mapStateToProps, mapDispatchToProps)(NavNotification);
// export default NavNotification;
