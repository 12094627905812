import{
    FETCH_COMPLETED_RESULTS_REQUEST,
    FETCH_COMPLETED_RESULTS_FIALURE,
    FETCH_COMPLETED_RESULTS_SUCCESS
   } from "constants/CompletedResults"
   
const initState = {
     list : [],
     loading : false,
     errorMessage : ""
}

const CompletedResults = (state = initState, action) =>{
    switch(action.type)
    {
       case FETCH_COMPLETED_RESULTS_REQUEST:
        return {
            ...state,
            loading : true
        } 
       case FETCH_COMPLETED_RESULTS_SUCCESS : 
        return {
             ...state, 
             loading : false,
             list : action.payload
        }
        case FETCH_COMPLETED_RESULTS_FIALURE :
        return {
            ...state,
            loading : false,
            errorMessage : action.payload
        }
        default : return state
    }
}

export default CompletedResults;