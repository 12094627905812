import {
  FETCH_DETAILED_PATIENT_RESULT_FAILURE,
  FETCH_DETAILED_PATIENT_RESULT_REQUEST,
  FETCH_DETAILED_PATIENT_RESULT_SUCCESS,
  FETCH_PATIENTRESULTS_FAILURE,
  FETCH_PATIENTRESULTS_REQUEST,
  FETCH_PATIENTRESULTS_SUCCESS,
  FETCH_PATIENT_HISTORY_FAILURE,
  FETCH_PATIENT_HISTORY_REQUEST,
  FETCH_PATIENT_HISTORY_SUCCESS,
} from "constants/PatientResult";

export const fetchPatientResultsRequest = (startDate, endDate, agency, department) => ({
  type: FETCH_PATIENTRESULTS_REQUEST,
  payload : {
      startDate, 
      endDate, 
      agency,
      department
  }
});

export const fetchPatientResultsSuccess = (patientresults) => ({
  type: FETCH_PATIENTRESULTS_SUCCESS,
  payload: patientresults,
});

export const fetchPatientResultsFailure = (error) => ({
  type: FETCH_PATIENTRESULTS_FAILURE,
  payload: error,
});

export const fetchPatientHistoryRequest = (startDate,
  endDate,searchKey,mrnnumber,phonenumber,fullname,patientid, sampleid) => ({
  type: FETCH_PATIENT_HISTORY_REQUEST,
  payload : {
    startDate,
  endDate,searchKey,mrnnumber,
    sampleid, 
    patientid, 
    fullname,
    phonenumber
   }
});

export const fetchPatientHistorySucess = (searchedPatientList) => ({
  type: FETCH_PATIENT_HISTORY_SUCCESS,
  payload : searchedPatientList
});


export const fetchPatientHistoryFailure = (errorMessage) => ({
  type: FETCH_PATIENT_HISTORY_FAILURE,
  payload : errorMessage
});


export const fetchDetailedPatientResult = (patientid) => ({
  type: FETCH_DETAILED_PATIENT_RESULT_REQUEST,
  payload : patientid
});

export const fetchDetailedPatientSuccess = (patientResult) => ({
  type: FETCH_DETAILED_PATIENT_RESULT_SUCCESS,
  payload : patientResult
});


export const fetchDetailedPatientFailure = (errorMessage) => ({
  type: FETCH_DETAILED_PATIENT_RESULT_FAILURE,
  payload : errorMessage
});