export const SHOW_NEW_ORDER_NEW_FORM = "SHOW_NEW_ORDER_NEW_FORM";
export const SHOW_NEW_ORDER_SEARCH_FORM = "SHOW_NEW_ORDER_SEARCH_FORM";
export const SHOW_NEW_ORDER_EDIT_FORM = "SHOW_NEW_ORDER_EDIT_FORM";
export const SET_NEW_ORDER_VALIDATION_ERRORS = "SET_NEW_ORDER_VALIDATION_ERRORS";

export const FETCH_NEW_ORDER_TESTS_REQUEST = "FETCH_NEW_ORDER_TESTS_REQUEST";
export const FETCH_NEW_ORDER_TESTS_SUCCESS = "FETCH_NEW_ORDER_TESTS_SUCCESS";
export const FETCH_NEW_ORDER_TESTS_FAILURE = "FETCH_NEW_ORDER_TESTS_FAILURE";

export const FETCH_LOCATION_LOOKUP_REQUEST = "FETCH_LOCATION_LOOKUP_REQUEST";
export const FETCH_LOCATION_LOOKUP_SUCCESS = "FETCH_LOCATION_LOOKUP_SUCCESS";
export const FETCH_LOCATION_LOOKUP_FAILURE = "FETCH_LOCATION_LOOKUP_FAILURE";

export const FETCH_ZONE_REQUEST = "FETCH_ZONE_REQUEST";
export const FETCH_ZONE_SUCCESS = "FETCH_ZONE_SUCCESS";
export const FETCH_ZONE_FAILURE = "FETCH_ZONE_FAILURE";

export const FETCH_WOREDA_REQUEST = "FETCH_WOREDA_REQUEST";
export const FETCH_WOREDA_SUCCESS = "FETCH_WOREDA_SUCCESS";
export const FETCH_WOREDA_FAILURE = "FETCH_WOREDA_FAILURE";

export const UPDATE_NEW_ORDER_REQUEST = "UPDATE_NEW_ORDER_REQUEST";
export const UPDATE_NEW_ORDER_SUCCESS = "UPDATE_NEW_ORDER_SUCCESS";
export const UPDATE_NEW_ORDER_FAILURE = "UPDATE_NEW_ORDER_FAILURE";

export const DELETE_NEW_ORDER_REQUEST = "DELETE_NEW_ORDER_REQUEST";
export const DELETE_NEW_ORDER_SUCCESS = "DELETE_NEW_ORDER_SUCCESS";
export const DELETE_NEW_ORDER_FAILURE = "DELETE_NEW_ORDER_FAILURE";

export const CREATE_NEW_ORDER_REQUEST = "CREATE_NEW_ORDER_REQUEST";
export const CREATE_NEW_ORDER_SUCCESS = "CREATE_NEW_ORDER_SUCCESS";
export const CREATE_NEW_ORDER_FAILURE = "CREATE_NEW_ORDER_FAILURE";

export const SHOW_PAYMENT_DRAWER = "SHOW_PAYMENT_DRAWER";
export const CLOSE_PAYMENT_DRAWER = "CLOSE_PAYMENT_DRAWER";

export const FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST = "FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST";
export const FETCH_EXISTING_NEW_ORDER_PATIENT_SUCCESS = "FETCH_EXISTING_NEW_ORDER_PATIENT_SUCCESS";
export const FETCH_EXISTING_NEW_ORDER_PATIENT_FAILURE = "FETCH_EXISTING_NEW_ORDER_PATIENT_FAILURE";
export const CLEAR_EXISTING_NEW_ORDER_PATIENT_CAPACITIES = "CLEAR_EXISTING_NEW_ORDER_PATIENT";

export const UPDATE_CS_OR_FS_NUMBER_REQUEST = "UPDATE_CS_OR_FS_NUMBER_REQUEST";
export const UPDATE_CS_OR_FS_NUMBER_SUCCESS = "UPDATE_CS_OR_FS_NUMBER_SUCCESS";
export const UPDATE_CS_OR_FS_NUMBER_FAILURE = "UPDATE_CS_OR_FS_NUMBER_FAILURE";