import fetch from "auth/FetchInterceptor";

const DailyFinancialSummary = {};

DailyFinancialSummary.get = function (payload) {
  console.log('--->pay',payload)
  return fetch({
    url: `/reports/daily-financial-summary?startdate=${payload.startDate}&enddate=${payload.endDate}&agency=${payload.agency}
    &cashiercode=${payload.cashiercode}&cashorcredit=${payload.cashorcredit}`,
    // mode: 'no-cors'
  });
};

export default DailyFinancialSummary;