import {UserOutlined } from "@ant-design/icons";
import { Avatar, notification } from "antd";
 export const ShowNotification = (type, placement, BodyMessage) =>{
      notification[type]({
         placement : placement,
         message : "Message",
         description: BodyMessage,
         icon: (
            <Avatar src={"https://www.gravatar.com/avatar/icldev@gmail.com"} icon={<UserOutlined />} /> // Custom avatar with fallback icon
          ),
        })
  }