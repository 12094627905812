import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Input, Button, Modal, Menu, Dropdown, message, Space, Tabs } from 'antd';
import styled from 'styled-components';
import { ShowNotification } from 'components/shared-components/NotificationApi/ShowNotification';
import { CURRENT_USER } from 'redux/constants/Auth';
import './styles.css'
import { useHistory } from 'react-router-dom'
import {LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, Legend} from 'recharts'
import { DownOutlined } from '@ant-design/icons';
const { TabPane } = Tabs

const cardStyle = {
  padding: '30px',
  transition: 'background-color 0.3s ease-in-out',
  boxShadow: '0 0 20px 0 rgba(0,0,0,0.1)',
  borderRadius: '10px',
  marginBottom: '20px',
  marginTop: '0px',
};

const smallCardStyle1 = {
  backgroundColor: '#ADD8E6',
  borderStyle: 'solid',
  borderWidth: '1px',
  //borderColor: 'blue',
  color: 'black',
  boxShadow: '0 0 20px 0 rgba(0, 123, 255, 0.5)'
}

const smallCardStyle2 = {
  backgroundColor: '#ADD8E6',
  borderStyle: 'solid',
  borderWidth: '1px',
  //borderColor: 'green',
  color: 'black',
  boxShadow: '0 0 20px 0 rgba(0, 123, 255, 0.5)'
}

const smallCardStyle3 = {
  backgroundColor: '#ADD8E6',
  borderStyle: 'solid',
  borderWidth: '2px',
  borderColor:'red',
  animation: 'floatAnimation 1.5s infinite',
  color: 'black',
  cursor: 'pointer',
}

const smallCardStyle4 = {
  backgroundColor: 'lightgreen',
  borderStyle: 'dashed',
  borderWidth: '2px',
  borderColor:'lightgreen',
  animation: 'floatAnimation 1.5s infinite',
  color: 'black',
  cursor: 'pointer',
}

const Search = Input.Search

const dataMalaria = [
  {month: 'Jan', pv: 400, pf: 2400, pm: 2400},
  {month: 'Feb', pv: 120, pf: 240, pm: 240},
  {month: 'March', pv: 200, pf: 1398, pm: 2210},
  {month: 'April', pv: 278, pf: 3908, pm: 2000},
  {month: 'May', pv: 189, pf: 4800, pm: 2181},
  {month: 'June', pv: 239, pf: 3800, pm: 2500},
  {month: 'July', pv: 349, pf: 4300, pm: 2100},
  {month: 'August', pv: 400, pf: 2400, pm: 2400},
  {month: 'Sept', pv: 120, pf: 240, pm: 240},
  {month: 'Oct', pv: 200, pf: 1398, pm: 2210},
  {month: 'Nov', pv: 278, pf: 3908, pm: 2000},
  {month: 'Dec', pv: 189, pf: 4800, pm: 2181},
];

const dataTB = [
  {month: 'Jan', cases: 400, deaths: 2400},
  {month: 'Feb', cases: 120, deaths: 240},
  {month: 'March', cases: 200, deaths: 1398},
  {month: 'April', cases: 278, deaths: 3908},
  {month: 'May', cases: 189, deaths: 4800},
  {month: 'June', cases: 239, deaths: 3800},
  {month: 'July', cases: 349, deaths: 4300},
  {month: 'August', cases: 400, deaths: 2400},
  {month: 'Sept', cases: 120, deaths: 240},
  {month: 'Oct', cases: 200, deaths: 1398},
  {month: 'Nov', cases: 278, deaths: 3908},
  {month: 'Dec', cases: 189, deaths: 4800},
];

const dataHIV = [
  {month: 'Jan', cases: 400, deaths: 20},
  {month: 'Feb', cases: 120, deaths: 40},
  {month: 'March', cases: 200, deaths: 1398},
  {month: 'April', cases: 278, deaths: 3908},
  {month: 'May', cases: 189, deaths: 4800},
  {month: 'June', cases: 239, deaths: 3800},
  {month: 'July', cases: 349, deaths: 4300},
  {month: 'August', cases: 400, deaths: 2400},
  {month: 'Sept', cases: 120, deaths: 240},
  {month: 'Oct', cases: 200, deaths: 1398},
  {month: 'Nov', cases: 278, deaths: 3908},
  {month: 'Dec', cases: 189, deaths: 4800},
]


const HoverableCol = styled(Col)`
  &:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }
`;

  const user = JSON.parse(localStorage.getItem(CURRENT_USER))
  if(user?.role === "doctor" && user?.token !== null){
    ShowNotification("success", "topRight", "Welcome Doctor");
  }


const items = [
  {
    label: 'Malaria',
    key: '1',
  },
  {
    label: 'TB',
    key: '2',
  },
  {
    label: 'HIV',
    key: '3',
  },
];

const TabItems = [
  {
    key: '1',
    label: 'Public Practice',
    children: 'Public practice page',
  },
  {
    key: '2',
    label: 'Private Practice',
    children: 'Private practice page',
  },
];

const onTabClick = (key) => {
  console.log(key);
}


const DoctorDashboard = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isResumedTestsVisible, setIsResumedTestsVisible] = useState(false)
  const history = useHistory()
  const [disease, setDisease] = useState("Malaria");
  const [readyResults, setReadyResults] = useState(null);
  const [panicResults, setPanicResults] = useState(null);
  const [dailyReleasedTests, setDailyReleasedTests] = useState(null);
  const [statOrders, setStatOrders] = useState(null);
  const [topTests, setTopTests] = useState(null);
  const [samplesInProcess, setSamplesInProcess] = useState(null);
  const [isModalVisible2, setIsModalVisible2] = useState(null);
  const [pendingPatientResult, setPendingPatientResult] = useState(null);


  //const backendUrl = "http://127.0.0.1:8000/api/reports/patientresult-all"
  const baseUrl = "http://127.0.0.1:8000/api"

  useEffect(() => {
    const fetchReadyResultsData = async () => {
      const response = await fetch(baseUrl+'/reports/patientresult-all', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("auth_token")}`
        }
      })
      const fetchedJSONData = await response.json()
      console.log('Fetched ready result', fetchedJSONData)
      setReadyResults(fetchedJSONData.data?.length ?? 0)
    }
    fetchReadyResultsData()

    const fetchPanicResultsData = async () => {
      const response = await fetch(baseUrl+'/reports/patientresult-panic', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("auth_token")}`
        }
      })
      const fetchedJSONData = await response.json()
      setPanicResults(fetchedJSONData.data?.length ?? 0)
    }
    fetchPanicResultsData()

    const fetchDailyReleasedTestsData = async () => {
      const response = await fetch(baseUrl+'/reports/daily-released-tests', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("auth_token")}`
        }
      })
      const fetchedJSONData = await response.json()
      setDailyReleasedTests(fetchedJSONData.data?.length ?? 0)
    }
    fetchDailyReleasedTestsData()

    const fetchStatOrdersData = async () => {
      const response = await fetch(baseUrl+'/reports/stat-orders', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("auth_token")}`
        }
      })
      const fetchedJSONData = await response.json()
      setStatOrders(fetchedJSONData.data?.length ?? 0)
    }
    fetchStatOrdersData()

    const fetchTopTestsData = async () => {
      const response = await fetch(baseUrl+'/reports/toptests', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("auth_token")}`
        }
      })
      const fetchedJSONData = await response.json()
      setTopTests(fetchedJSONData.data?.length ?? 0)
    }
    fetchTopTestsData()
    
    const fetchSamplesInProcessData = async () => {
      const response = await fetch(baseUrl+'/reports/sample-in-process', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("auth_token")}`
        }
      })
      const fetchedJSONData = await response.json()
      console.log('Sample in process data', fetchedJSONData)
      setSamplesInProcess(fetchedJSONData.data?.length ?? 0)
    }
    fetchSamplesInProcessData()

    const fetchPendingPatientResultsData = async () => {
      const response = await fetch(baseUrl+'/reports/patientresult-pending', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("auth_token")}`
        }
      })
      const fetchedJSONData = await response.json()
      console.log('Pending patient results data', fetchedJSONData)
      setPendingPatientResult(fetchedJSONData.data?.length ?? 0)
    }
    fetchPendingPatientResultsData()
  }, [])

  const handleCardClick = () => {
    setIsModalVisible(true);
  };

  const handleResumedCardClick = () => {
    setIsResumedTestsVisible(true); //
  }

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setIsResumedTestsVisible(false); //
  };

  const handleStatOrderModalClick = () => {
    setIsModalVisible2(true);
  }

  const handleStatOrderModalCancel = () => {
    setIsModalVisible2(false);
  }

  const handleViewDetails = () => {
    history.push('/doctor/results/panic-and-abnormal/all')
  }

  // Render the line chart component
  const renderLineChartMalaria = (
    <ResponsiveContainer width="100%" height={500}>
      <LineChart 
        width={500} 
        height={300} 
        data={dataMalaria}>
        <Line type="monotone" dataKey="pv" stroke="#8884d8"/>
        <Line type="monotone" dataKey="pf" stroke="#82ca9d"/>
        <Line type="monotone" dataKey="pm" stroke="red"/>
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Legend />
      </LineChart>
    </ResponsiveContainer>
  );

  // Render line chart TB
  const renderLineChartTB = (
    <ResponsiveContainer width="100%" height={500}>
      <LineChart
        width={500}
        height={300}
        data={dataTB}>
        <Line type="monotone" dataKey="cases" stroke="#8884d8"/>
        <Line type="monotone" dataKey="deaths" stroke="#FF0000"/>
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Legend />
      </LineChart>
    </ResponsiveContainer>
  )

  // Render line chart HIV
  const renderLineChartHIV = (
    <ResponsiveContainer width="100%" height={500}>
      <LineChart
        width={500}
        height={300}
        data={dataHIV}>
        <Line type="monotone" dataKey="cases" stroke="#8884d8"/>
        <Line type="monotone" dataKey="deaths" stroke="#FF0000"/>
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Legend />
      </LineChart>
    </ResponsiveContainer>
  )

  const handleMenuClick = (e) => {
    message.info(`You are seeing data for ${e.item.props.children[1]}`)
    //console.log(e.item.props.children[1]);
    setDisease(e.item.props.children[1]);
  }
  // drop down menu
  const menu = (
    <Menu onClick={handleMenuClick}>
      {items.map(item => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );


  return (
  <div style={cardStyle}>
    <Tabs defaultActiveKey="1" onChange={onTabClick}>
      {TabItems.map(item => (
        <TabPane tab={item.label} key={item.key}>
        { item.children }
        </TabPane>
      ))}
    </Tabs>
    <Search
    placeholder="input patient name or MRN"
    style={{ width: 300, marginBottom: 20 }}
    onSearch={value => console.log(value)}
    />
    <div className='dashboard-items'>
          <h3>Order Status</h3>
    <Row gutter={16}>
    <HoverableCol span={5}>
      <Card title={<span style={{ color: 'black' }}>Top Tests</span>} bordered={false} style={smallCardStyle1}>{topTests}</Card>
    </HoverableCol>

    <HoverableCol span={5}>
        <Card title={<span style={{ color: 'black' }}>Daily Released Tests</span>} bordered={false} style={smallCardStyle2}>{dailyReleasedTests}</Card>
    </HoverableCol>

    <HoverableCol span={5}>
        <Card title={<span style={{ color: 'black' }}>Samples in processing</span>} bordered={false} style={smallCardStyle2}>{samplesInProcess}</Card>
    </HoverableCol>

    <HoverableCol span={5}>
        <Card 
          title={<span style={{ color: 'black' }}>Resumed Tests</span>}
          bordered={false} 
          style={smallCardStyle4} 
          onClick={handleResumedCardClick}> 
          38
        </Card>
        <Modal
          title="Resumed Tests"
          visible={isResumedTestsVisible}
          onCancel={handleModalCancel}
        >
          <p>LFT</p>
          <p>Urine Analysis</p>
          <p>CRP</p>
        </Modal>
        
    </HoverableCol>
    </Row>
    <h3>Result Status</h3>
    <Row gutter={16}>
      <HoverableCol span={5}>
        <Card title={<span style={{ color: 'black' }}>Ready Results</span>} bordered={false} style={smallCardStyle1}>{readyResults}</Card>
      </HoverableCol>
      <HoverableCol span={5}>
        <Card title={<span style={{ color: 'black' }}>Pending Results</span>} bordered={false} style={smallCardStyle1}>{pendingPatientResult}</Card>
      </HoverableCol>
      <HoverableCol span={5}>
      <Card 
          title={<span style={{ color: 'black' }}>Stat Results</span>}
          bordered={false} 
          style={ statOrders > 0 ? smallCardStyle3: smallCardStyle2} 
          onClick={handleStatOrderModalClick}> 
          {statOrders}
        </Card>
        <Modal
          title="Stat orders"
          visible={isModalVisible2}
          onCancel={handleStatOrderModalCancel}
          footer={[
            <Button 
              key="viewDetails" 
              onClick={handleViewDetails}
              type='primary'
              disabled={statOrders === 0}
            > View Details
            </Button>
          ]}
        >
          {/* <p> Mr. Abebe has elevated levels of <strong>VLDL!!</strong> </p>
          <p> Mr. Abebe has elevated levels of <strong>Cr!!</strong> </p>
          <p> Mrs. Webit has elevated levels of <strong>ALT!!</strong> </p>
          <p> Mrs. Webit has elevated levels of <strong>AST!!</strong> </p> */}
          {statOrders === 0 && <strong>You dont have any STAT results currently.</strong>}
          {statOrders > 0 && 
            <strong>
              The STAT results will be displayed here
            </strong>
          }
        </Modal>
      </HoverableCol>
      
      <HoverableCol span={5}>
        <Card 
          title={<span style={{ color: 'black' }}>Panic Results</span>}
          bordered={false} 
          style={ panicResults > 0 ? smallCardStyle3 : smallCardStyle2 } 
          onClick={handleCardClick}> 
          {panicResults}
        </Card>
        <Modal
          title="Panic Results"
          visible={isModalVisible}
          onCancel={handleModalCancel}
          footer={[
            <Button 
              key="viewDetails" 
              onClick={handleViewDetails}
              type='primary'
              disabled={panicResults === 0}
            > View Details
            </Button>
          ]}
        >
          {panicResults === 0 && <strong>You dont have any panic results currently.</strong>}
          {panicResults > 0 && 
            <strong> 
              <p> Mr. Abebe has elevated levels of <strong>VLDL!!</strong> </p>
              <p> Mr. Abebe has elevated levels of <strong>Cr!!</strong> </p>
              <p> Mrs. Webit has elevated levels of <strong>ALT!!</strong> </p>
              <p> Mrs. Webit has elevated levels of <strong>AST!!</strong> </p>
            </strong>}
        </Modal>
    </HoverableCol>
    </Row>
    </div>


    <Dropdown overlay={menu}>
      <Button 
        onClick={(e) => e.preventDefault()}
        type='primary'
        style={{backgroundColor: 'white', color: '#3e79f7'}}
      >
        <Space>
          Select infectious disease data
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
    <h2>Annual prevalence of {disease}</h2>
    {disease === "Malaria" ? renderLineChartMalaria : null}
    {disease === "TB" ? renderLineChartTB : null}
    {disease === "HIV" ? renderLineChartHIV : null}
  </div>
  )
}

export default DoctorDashboard