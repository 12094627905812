import React from 'react';
import { connect } from 'react-redux';
import { persistor } from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { PersistGate } from 'redux-persist/integration/react'

function App({
  currentTheme
}) {
  const themes = {
    dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
  };

  return (
    <div className="App">
      <ThemeSwitcherProvider themeMap={themes} defaultTheme={currentTheme} insertionPoint="styles-insertion-point">
        <Router>
          <PersistGate persistor={persistor}>
            <Switch>
              <Route path="/" component={Views} />
            </Switch>
          </PersistGate>
        </Router>
      </ThemeSwitcherProvider>
    </div>
  );
}
const mapStateToProps = (state) => ({
  currentTheme: state.theme.currentTheme
})
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(App);
