import {
  CREATE_RESOURCE_REQUEST,
  CREATE_RESOURCE_REQUEST_FAILURE,
  CREATE_RESOURCE_REQUSET_SUCCESS,
  FETCH_RESOURCE_REQUEST,
  FETCH_RESOURCE_REQUEST_FAILURE,
  FETCH_RESOURCE_REQUSET_SUCCESS,
  UPDATE_RESOURCE_REQUEST,
  UPDATE_RESOURCE_SUCCESS,
  UPDATE_RESOURCE_FAILURE,
  UPDATE_RESOURCE_REQUEST_STATUS,
  UPDATE_RESOURCE_REQUEST_STATUS_SUCCESS,
  UPDATE_RESOURCE_REQUEST_STATUS_FAILURE,
} from "constants/Logistics"

export const fetchResourceRequest =()=>{
  return {
      type : FETCH_RESOURCE_REQUEST,
  }
}

export const fetchResourceRequestSuccess = (data) =>{
  return {
       type : FETCH_RESOURCE_REQUSET_SUCCESS,
       payload : data
  }
}

export const fetchResourceRequestFailure = error =>{
  return {
       type : FETCH_RESOURCE_REQUEST_FAILURE,
       payload : error
  }
}

export const createResourceRequest =(data)=>{
  return {
      type : CREATE_RESOURCE_REQUEST,
      payload : data
  }
}

export const createResourceRequestSuccess = (data) =>{
  return {
       type : CREATE_RESOURCE_REQUSET_SUCCESS,
       payload : data
  }
}

export const createResourceRequestFailure = error =>{
  return {
       type : CREATE_RESOURCE_REQUEST_FAILURE,
       payload : error,
  }
}

export const updateResourceRequest = (data) => ({
  type: UPDATE_RESOURCE_REQUEST,
  payload: data,
  
});

export const updateResourceRequestSuccess = (data) => ({
  type: UPDATE_RESOURCE_SUCCESS,
  payload:data,
  
});

export const updateResourceRequestFailure= (error) => ({
  type: UPDATE_RESOURCE_FAILURE,
  payload: error,
});

export const updateResourceRequestStatus = (id, whichButton) => ({
  type: UPDATE_RESOURCE_REQUEST_STATUS,
  payload: {
    id, whichButton
  },
  
});

export const updateResourceRequestStatusSuccess = (data) => ({
  type: UPDATE_RESOURCE_REQUEST_STATUS_SUCCESS,
  payload:data,
  
});

export const updateResourceRequestStatusFailure= (error) => ({
  type: UPDATE_RESOURCE_REQUEST_STATUS_FAILURE,
  payload: error,
});




